import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {AuthService} from '../../auth/auth.service';
import {GroupsService} from '../../services/groups.service';
import {Subscription} from 'rxjs';
import {BoardService} from '../../services/kanban.service';

@Component({
    selector: 'app-new-kanban-task-dialog',
    templateUrl: 'new-kanban-task-dialog.html',
    styleUrls: ['new-kanban-task-dialog.scss']
})
export class NewKanbanTaskDialogComponent implements OnInit, OnDestroy {

    kanbanTaskFormGroup: FormGroup;
    sub: Subscription;
    boards = [];
    type;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        private boardService: BoardService,
        public dialogRef: MatDialogRef<NewKanbanTaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.type = this.data.type;
    }

    ngOnInit() {
        this.kanbanTaskFormGroup = this._formBuilder.group({
            board_id: [null, Validators.required],
            name: [null, Validators.required],
            description: [null]
        });
        this.sub = this.boardService
            .getUserBoards(this.type)
            .subscribe(boards => {
                this.boards = boards;
            });
    }

    save() {
        const task = {
            name: this.kanbanTaskFormGroup.value.name,
            description: this.kanbanTaskFormGroup.value.description,
            createdAt: new Date(),
        };
        const result = {
            board_id: this.kanbanTaskFormGroup.value.board_id,
            task: task
        };
        this.dialogRef.close(result);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
