import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {

    constructor(
        @Inject('firebaseAmd') public firebaseAmd: AngularFirestore,
        @Inject('firebaseAuthAmd') private firebaseAuthAmd: AngularFireAuth
    ) { }

}
