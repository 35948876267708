import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarComponent} from '../snackbar/snackbar.component';


@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    constructor(private snackBar: MatSnackBar) {
    }

    showSnackBar(message: string, status: string = 'info', duration = 2000) {
        this.snackBar.openFromComponent(SnackBarComponent, {
            duration: duration,
            data: {message: message, status: status},
            panelClass: status
        });
    }

}
