import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import sanityClient, { ClientConfig, SanityClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import {environment} from '../../environments/environment.prod';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SanityService {
    private client: SanityClient;
    private imageUrlBuilder: ImageUrlBuilder;
    private clientConfig: ClientConfig = {
        projectId: environment.sanity.projectId,
        dataset: environment.sanity.dataset,
        apiVersion: this.getApiVersion(),
        useCdn: false,
    };

    constructor(
        private http: HttpClient,
    ) {
        this.client = this.createClient();
        this.imageUrlBuilder = imageUrlBuilder(this.client);
    }

    getImageUrlBuilder(source: SanityImageSource): ImageUrlBuilder {
        return this.imageUrlBuilder.image(source);
    }

    getData(query) {
        return this.client.fetch(query);
    }

    fetch<T>(query: string): Observable<T> {
        const url = `${this.generateSanityUrl()}${encodeURIComponent(query)}`;
        return this.http.get(url).pipe(map((response: any) => response.result as T));
    }

    private createClient(): SanityClient {
        return sanityClient(this.clientConfig);
    }

    private generateSanityUrl(): string {
        const { apiVersion, dataset, projectId } = this.clientConfig;

        const baseUrl = `https://${projectId}.api.sanity.io/`;

        return `${baseUrl}${apiVersion}/data/query/${dataset}?query=`;
    }

    private getApiVersion(): string {
        return `v${new Date().toISOString().split('T')[0]}`;
    }
}
