import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {GlobalService} from '../../services/global.service';
import {ReferralsService} from '../../services/referrals.service';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.html',
    styleUrls: ['confirm-dialog.scss']
})
export class ConfirmDialogComponent implements OnInit {

    form: FormGroup;
    task: FormGroup;
    details_schema;
    details_values;
    form_label = 'Additional Details';
    show_create_task = false;
    task_creation_requested = false;

    constructor(
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        public referralsService: ReferralsService,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        // console.log(this.data);
        this.details_schema = this.data.details_schema;
        this.details_values = this.data.details_values ?? {};
        this.show_create_task = this.data.show_create_task ?? false;
    }

    ngOnInit() {
        // build form using this.details_schema
        this.form = this._formBuilder.group({});
        this.details_schema.forEach(field => {
            this.form.addControl(
                field.name,
                new FormControl(
                    this.details_values[field.name],
                    field.required ? (field.type === 'checkbox' ? Validators.requiredTrue : Validators.required) : Validators.nullValidator
                )
            );
        });
        // set default values for any "date" type fields, taking into account that sometimes the value is a firestore Timestamp and sometimes it's a Date object
        this.details_schema.forEach(field => {
            if (field.type === 'date') {
                let default_value_formatted;
                if (this.details_values[field.name]?.toDate) {
                    default_value_formatted = this.details_values[field.name].toDate();
                } else {
                    default_value_formatted = this.details_values[field.name];
                }
                this.form.get(field.name).setValue(!this.details_values[field.name] ? new Date() : default_value_formatted);
            }

            // if field.type is 'select' and 'default_first_option' is true, set the first option as the default value
            if (field.type === 'select' && field.default_first_option) {
                this.form.get(field.name).setValue(field.options[0]);
            }
        });

        if (this.show_create_task) {
            this.initTaskForm();
        }
    }

    initTaskForm() {
        this.task = this._formBuilder.group({
            type: ['To-Do', Validators.required],
            due_date: [this.referralsService.dueDateOptions[2].value, Validators.required],
            notes: [''],
            completed: [false],
        });
    }

    onTaskFormGroupChanged(task) {
        this.task.patchValue(task);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close({
            details: this.form.getRawValue(),
            task: this.task_creation_requested ? this.task.getRawValue() : null
        });
    }

}
