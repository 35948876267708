import {AfterViewInit, Component, Inject, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {GroupsService} from '../../services/groups.service';
import {AuthService} from '../../auth/auth.service';
import * as moment from 'moment';
import {Observable, Subscription, throwError} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ReferralsService} from '../../services/referrals.service';
import {PhoneNumberUtil, PhoneNumber, PhoneNumberFormat as PNF} from 'google-libphonenumber';
import {map, pairwise, startWith} from 'rxjs/operators';
import {AutoCompleteValidator} from '../../autocomplete-validator';
import {primaryDiagnoses, primaryDiagnosesWithInsurance} from './types/types';
import {DISALLOW_SMS} from './rules';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog';
import {firebase} from 'firebaseui-angular';
import {UnsavedChangesDialogComponent} from '../unsaved-changes-dialog/unsaved-changes-dialog';
import {AssignDialogComponent} from '../assign-dialog/assign-dialog';
import {MatTableDataSource} from '@angular/material/table';

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-referral-dialog',
    templateUrl: 'referral-dialog.html',
    styleUrls: ['referral-dialog.scss']
})
export class ReferralDialogComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    private _originalClose: Function;

    protected readonly primaryDiagnoses = primaryDiagnoses;
    protected readonly primaryDiagnosesWithInsurance = primaryDiagnosesWithInsurance;

    providersSubscription: Subscription;
    appointmentTypeDetailsSubscription: Subscription;
    specialtiesSubscription: Subscription;
    insurancesSubscription: Subscription;
    treatmentTypesSubscription: Subscription;
    groupTherapySettingsSubscription: Subscription;

    tasksDataSource = new MatTableDataSource(null);

    filteredProviders: Observable<{}[]>;
    filteredAdditionalProviders: Observable<{}[]>;
    filteredProviders2: Observable<{}[]>;

    referral;
    referralFormGroup: FormGroup;
    smsForm: FormGroup;
    specialties = {};
    insurances = {};
    filteredInsurances = {};
    providers = [];
    appointment_type_details = {};
    providers_preference = [];
    treatment_types = [];
    group_therapy_settings = [];
    selected_appointment_type_group_leaders_emails = [];
    dobCtrl: FormControl;

    disableAnimation = true;
    loadingTasks = true;

    openConfirmDialogRef;
    unsavedChangesDialogRef;
    assignDialogRef;

    customHyperlink = 'https://claritychi.com/search';

    viewMessageHistory = false;

    tasksColumns: string[] = [
        'status',
        'type',
        'notes',
        // 'assigned_to',
        'created_by',
        'due_date'
    ];

    public mask = {
        guide: true,
        showMask : true,
        // placeholderChar: '\u2000',
        mask: [/[0,1]/, /\d/, '/', /[0,1,2,3]/, /\d/, '/', /[1,2]/, /[9,0]/, /\d/, /\d/]
    };

    constructor(
        private afs: AngularFirestore,
        private http: HttpClient,
        private toastsService: ToastsService,
        private authService: AuthService,
        public globalService: GlobalService,
        public projectsService: ProjectsService,
        private groupsService: GroupsService,
        public referralsService: ReferralsService,
        public dialogRef: MatDialogRef<ReferralDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
            this.referral = data || null;
            this._originalClose = this.dialogRef.close;
            this.dialogRef.close = this.closeDialog.bind(this);
    }

    getReferralTypeLabel() {
        return this.referralsService.types.find(t => t.value === this.referral.type)?.label ?? 'Unknown';
    }

    async onChangeTaskCompletion(task_id, completed) {
        try {
            const taskDocRef = this.afs.collection<any>('referrals')
                .doc(this.referral.id)
                .collection('tasks')
                .doc(task_id);
            taskDocRef.update({
                'completed': completed,
                'completed_at': completed ? new Date() : null,
                'completed_by_email': completed ? this.authService.user.email : null,
                'completed_by': completed ? this.authService.user.displayName : null,
            }).then(() => {
                // using await doesn't work here and causes rendering issues for the toast, so using .then and .catch instead
                this.toastsService.showSnackBar('Task saved', 'success');
            }).catch(e => {
                throw new Error(e);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    ngOnInit() {
        // TODO abstract a referral so we can have types of referrals as classes with unique formGroup inits, and also unique html templates
        console.log(this.referral);
        let sms_enabled_default = true;
        if (this.referral.type === 'group' || this.referral.type === 'tms' || this.referral.type === 'php-iop') {
            sms_enabled_default = false;
        }

        this.dobCtrl = new FormControl('', [Validators.pattern(/^\d{2}\/\d{2}\/\d{4}$/), Validators.required]);
        this.referralFormGroup = this._formBuilder.group({
            type: [this.referral?.type ?? '', Validators.required],
            source: [this.referral?.source ?? 'internal'],
            created: [this.referral?.created ?? null],
            is_informed: [this.referral?.is_informed ?? ''],
            medium_preference: [this.referral?.medium_preference ?? ''],
            other_insurance: [this.referral?.other_insurance ? this.referral?.other_insurance : this.referral?.other_preferences ?? ''], // TODO deprecate other_preferences 1/1/2025
            office_code: [this.referral?.office_code ?? ''],
            chart_number: [this.referral?.chart_number ?? ''],
            dob: [this.referral?.dob ?? '', Validators.required],
            patient_id: [this.referral?.patient_id ?? ''],
            phone: [this.referral?.phone ?? '', Validators.required],
            email: [this.referral?.email ?? ''],
            first_name: [this.referral?.first_name ?? '', Validators.required],
            last_name: [this.referral?.last_name ?? '', Validators.required],
            preferred_first_name: [this.referral?.preferred_first_name ?? ''],
            preferred_last_name: [this.referral?.preferred_last_name ?? ''],
            insurance: [this.referral?.insurance ?? '', Validators.required],
            referring_provider: [this.referral?.referring_provider ?? '', [Validators.required]],
            referral_visit: [this.referral?.referral_visit ?? null],
            response: [this.referral?.response ?? 'No Response'],
            messages_sent: [this.referral?.messages_sent ?? 0],
            important: [this.referral?.important ?? false, Validators.required],
            urgent: [this.referral?.urgent ?? false, Validators.required],
            additional_information: [this.referral?.additional_information ?? ''],
            last_message_date_created: [this.referral?.last_message_date_created ?? null],
            patient_id_in_alternate_office_code: [this.referral?.patient_id_in_alternate_office_code ?? null],
            sms_enabled: [this.referral?.sms_enabled ?? sms_enabled_default, Validators.required],
            waitlist_visit_start_datetime: [(this.referral.waitlist_visit_start_datetime ?? undefined)?.toDate()],
            status: [this.referral?.status ?? 'Active'],
            substatus: [this.referral?.substatus ?? ''],
            history: [this.referral?.history ?? []],
            is_existing_patient: [this.referral?.is_existing_patient ?? false],
            age_group: [this.referral?.age_group ?? 'unknown'],
            assigned_to: [this.referral?.assigned_to ?? ''],
        });

        this.referralFormGroup.addControl('referring_provider_organization', new FormControl(this.referral?.referring_provider_organization ?? ''));
        this.referralFormGroup.addControl('referring_provider_phone', new FormControl(this.referral?.referring_provider_phone ?? ''));
        this.referralFormGroup.addControl('referring_provider_email', new FormControl(this.referral?.referring_provider_email ?? ''));

        this.officeCodeChanged(this.referral?.office_code ?? '');

        if (this.referral?.type === 'therapy' || this.referral?.type === 'psychiatry') {
            this.referralFormGroup.addControl('treatment_type_preference', new FormControl(this.referral?.treatment_type_preference ?? '', Validators.required));
            this.referralFormGroup.addControl('provider_preference', new FormControl(this.referral?.provider_preference ?? '', Validators.required));
            this.referralFormGroup.addControl('gender_preference', new FormControl(this.referral?.gender_preference ?? '', Validators.required));
            this.referralFormGroup.addControl('location_preference', new FormControl(this.referral?.location_preference ?? '', Validators.required));
            this.referralFormGroup.addControl('primary_specialty', new FormControl(this.referral?.primary_specialty ?? '', Validators.required));
            this.referralFormGroup.addControl('secondary_specialty', new FormControl(this.referral?.secondary_specialty ?? ''));
        } else if (this.referral?.type === 'group') {
            this.referralFormGroup.addControl('group_office_code', new FormControl(this.referral?.group_office_code ?? '', Validators.required));
            this.referralFormGroup.addControl('group_id', new FormControl(this.referral?.group_id ?? '', Validators.required));
            this.referralFormGroup.addControl('group_name', new FormControl(this.referral?.group_name ?? '', Validators.required));
            this.referralFormGroup.addControl('group_leader', new FormControl(this.referral?.group_leader ?? '', Validators.required));
            this.referralFormGroup.addControl('group_leader_profile_url', new FormControl(this.referral?.group_leader_profile_url ?? '', Validators.required));
        } else if (this.referral?.type === 'tms') {
            this.referralFormGroup.addControl('location_preference', new FormControl(this.referral?.location_preference ?? ''));
            this.referralFormGroup.addControl('primary_diagnosis', new FormControl(this.referral?.primary_diagnosis ?? ''));
            this.referralFormGroup.addControl('secondary_diagnosis', new FormControl(this.referral?.secondary_diagnosis ?? ''));
            this.referralFormGroup.addControl('other_diagnoses', new FormControl(this.referral?.other_diagnoses ?? ''));
            this.referralFormGroup.addControl('diagnosis_notes', new FormControl(this.referral?.diagnosis_notes ?? ''));

            this.referralFormGroup.addControl('phq9_assessment_date', new FormControl((this.referral?.phq9_assessment_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('phq9_assessment_score', new FormControl(this.referral?.phq9_assessment_score ?? ''));

            this.referralFormGroup.addControl('assessment_scale', new FormControl(this.referral?.assessment_scale ?? ''));
            this.referralFormGroup.addControl('assessment_date', new FormControl((this.referral?.assessment_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('assessment_score', new FormControl(this.referral?.assessment_score ?? ''));

            this.referralFormGroup.addControl('mdd_diagnosis_flag', new FormControl(this.referral?.mdd_diagnosis_flag ?? false));
            this.referralFormGroup.addControl('ocd_diagnosis_flag', new FormControl(this.referral?.ocd_diagnosis_flag ?? false));

            this.referralFormGroup.addControl('current_medication_1_name', new FormControl(this.referral?.current_medication_1_name ?? ''));
            this.referralFormGroup.addControl('current_medication_1_classification', new FormControl(this.referral?.current_medication_1_classification ?? null));
            this.referralFormGroup.addControl('current_medication_1_dosage', new FormControl(this.referral?.current_medication_1_dosage ?? ''));
            this.referralFormGroup.addControl('current_medication_1_start_date', new FormControl((this.referral?.current_medication_1_start_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_1_end_date', new FormControl((this.referral?.current_medication_1_end_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_1_response', new FormControl(this.referral?.current_medication_1_response ?? ''));

            this.referralFormGroup.addControl('current_medication_2_name', new FormControl(this.referral?.current_medication_2_name ?? ''));
            this.referralFormGroup.addControl('current_medication_2_classification', new FormControl(this.referral?.current_medication_2_classification ?? null));
            this.referralFormGroup.addControl('current_medication_2_dosage', new FormControl(this.referral?.current_medication_2_dosage ?? ''));
            this.referralFormGroup.addControl('current_medication_2_start_date', new FormControl((this.referral?.current_medication_2_start_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_2_end_date', new FormControl((this.referral?.current_medication_2_end_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_2_response', new FormControl(this.referral?.current_medication_2_response ?? ''));

            this.referralFormGroup.addControl('current_medication_3_name', new FormControl(this.referral?.current_medication_3_name ?? ''));
            this.referralFormGroup.addControl('current_medication_3_classification', new FormControl(this.referral?.current_medication_3_classification ?? null));
            this.referralFormGroup.addControl('current_medication_3_dosage', new FormControl(this.referral?.current_medication_3_dosage ?? ''));
            this.referralFormGroup.addControl('current_medication_3_start_date', new FormControl((this.referral?.current_medication_3_start_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_3_end_date', new FormControl((this.referral?.current_medication_3_end_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('current_medication_3_response', new FormControl(this.referral?.current_medication_3_response ?? ''));

            this.referralFormGroup.addControl('psychotherapy_history_yes_currently', new FormControl(this.referral?.psychotherapy_history_yes_currently ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_yes_past', new FormControl(this.referral?.psychotherapy_history_yes_past ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_no', new FormControl(this.referral?.psychotherapy_history_no ?? ''));

            this.referralFormGroup.addControl('psychotherapy_history_yes_currently_provider', new FormControl(this.referral?.psychotherapy_history_yes_currently_provider ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_yes_currently_provider_license', new FormControl(this.referral?.psychotherapy_history_yes_currently_provider_license ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_yes_currently_start_date', new FormControl((this.referral?.psychotherapy_history_yes_currently_start_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('psychotherapy_history_yes_currently_end_date', new FormControl((this.referral?.psychotherapy_history_yes_currently_end_date || undefined)?.toDate()));

            this.referralFormGroup.addControl('psychotherapy_history_yes_past_provider', new FormControl(this.referral?.psychotherapy_history_yes_past_provider ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_yes_past_provider_license', new FormControl(this.referral?.psychotherapy_history_yes_past_provider_license ?? ''));
            this.referralFormGroup.addControl('psychotherapy_history_yes_past_start_date', new FormControl((this.referral?.psychotherapy_history_yes_past_start_date || undefined)?.toDate()));
            this.referralFormGroup.addControl('psychotherapy_history_yes_past_end_date', new FormControl((this.referral?.psychotherapy_history_yes_past_end_date || undefined)?.toDate()));

            this.referralFormGroup.addControl('psychotherapy_history_no_notes', new FormControl(this.referral?.psychotherapy_notes ?? ''));

            this.referralFormGroup.addControl('medical_records_received', new FormControl(this.referral?.medical_records_received ?? false));
            this.referralFormGroup.addControl('prior_auth_approved', new FormControl(this.referral?.prior_auth_approved ?? false));

            this.referralFormGroup.addControl('update_frequency', new FormControl(this.referral?.update_frequency ?? ''));
            this.referralFormGroup.addControl('other_update_frequency', new FormControl(this.referral?.other_update_frequency ?? ''));

        } else if (this.referral?.type === 'php-iop') {
            this.referralFormGroup.addControl('location_preference', new FormControl(this.referral?.location_preference ?? '', Validators.required));
            this.referralFormGroup.addControl('additional_referring_provider', new FormControl(this.referral?.additional_referring_provider ?? ''));
            this.referralFormGroup.addControl('assessment_notes', new FormControl(this.referral?.assessment_notes ?? ''));
            this.referralFormGroup.addControl('external_providers', new FormControl(this.referral?.external_providers ?? ''));
            this.referralFormGroup.addControl('primary_diagnosis', new FormControl(this.referral?.primary_diagnosis ?? ''));
            this.referralFormGroup.addControl('secondary_diagnosis', new FormControl(this.referral?.secondary_diagnosis ?? ''));
            this.referralFormGroup.addControl('other_diagnoses', new FormControl(this.referral?.other_diagnoses ?? ''));
            this.referralFormGroup.addControl('diagnosis_notes', new FormControl(this.referral?.diagnosis_notes ?? ''));
            this.referralFormGroup.addControl('needs_support', new FormControl(this.referral?.needs_support ?? ''));
            this.referralFormGroup.addControl('preferred_callback_time', new FormControl(this.referral?.preferred_callback_time ?? ''));
            this.referralFormGroup.addControl('interested_for', new FormControl(this.referral?.interested_for ?? ''));
            this.referralFormGroup.addControl('preferred_program_time', new FormControl(this.referral?.preferred_program_time ?? ''));

            this.referralFormGroup.addControl('insurance_subscriber_id', new FormControl(this.referral?.insurance_subscriber_id ?? ''));
            this.referralFormGroup.addControl('insurance_subscriber_phone', new FormControl(this.referral?.insurance_subscriber_phone ?? ''));
            this.referralFormGroup.addControl('insurance_subscriber_last_name', new FormControl(this.referral?.insurance_subscriber_last_name ?? ''));
            this.referralFormGroup.addControl('insurance_subscriber_first_name', new FormControl(this.referral?.insurance_subscriber_first_name ?? ''));
            this.referralFormGroup.addControl('insurance_subscriber_relationship', new FormControl(this.referral?.insurance_subscriber_relationship ?? ''));
            this.referralFormGroup.addControl('insurance_subscriber_address', new FormControl(this.referral?.insurance_subscriber_address ?? ''));
            this.referralFormGroup.addControl('patient_address', new FormControl(this.referral?.patient_address ?? ''));

            this.generateStageControls(this.referralsService.phpIopReferralStages);
            this.prettyPrintActions();

            // TODO verify if new statuses/fields break any automations
            // TODO make longer scrolling sections with each field on a separate line, less crammed per line
            // inspiration: https://community.hubspot.com/t5/Releases-and-Updates/Live-Lead-Management-in-Prospecting-Workspace/ba-p/867105
        }

        this.smsForm = this._formBuilder.group({
            body: ['', Validators.required],
        });

        this.initSubscriptions();
        this.loadReferralMessages();
        this.loadReferralTasks();
        this.referralSourceChanged();
        this.referralChanged();

        this.filteredProviders = this.referralFormGroup.get('referring_provider').valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );

        const additionalFilteredProvidersControl = this.referralFormGroup.get('additional_referring_provider');
        if (additionalFilteredProvidersControl) {
            this.filteredAdditionalProviders = additionalFilteredProvidersControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter2(value))
                );
        }


        const providerPreferenceControl = this.referralFormGroup.get('provider_preference');
        if (providerPreferenceControl) {
            this.filteredProviders2 = providerPreferenceControl.valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter2(value))
                );
        }
    }

    generateStageControls(stages) {
        for (const stage of stages) {
            this.referralFormGroup.addControl(stage.name, new FormControl(this.referral[stage.name] ?? 0, Validators.required));

            stage.statuses.forEach(status => {
                if (status.details && status.details.length) {
                    const statusFormGroup = new FormGroup({});
                    status.details.forEach(detail => {
                        const current_value = this.referral[`${stage.name}_${status.value}_details`] ? this.referral[`${stage.name}_${status.value}_details`][detail.name] : detail.type === 'text' ? '' : detail.type === 'boolean' ? false : detail.type === 'date' ? null : null;
                        const control = new FormControl(current_value);
                        statusFormGroup.addControl(detail.name, control);
                    });
                    this.referralFormGroup.addControl(`${stage.name}_${status.value}_details`, statusFormGroup);
                }
            });

            this.referralFormGroup.get(stage.name).valueChanges.pipe(
                startWith(this.referralFormGroup.get(stage.name).value),
                pairwise()
            ).subscribe(
                ([old, value]) => {
                    stage['previous_status'] = stage['recently_updated_status'] ?? 0;
                    stage['recently_updated_status'] = old ?? 0;
                }
            );
        }
    }

    private _filter(value: string) {
        let filterValue;
        if (value) {
            filterValue = value.toString().toLowerCase();
        } else {
            filterValue = '';
        }
        return this.providers.filter(provider => {
            return provider.name.toLowerCase().includes(filterValue) || provider.email.toLowerCase().includes(filterValue);
        });
    }

    private _filter2(value: string) {
        let filterValue;
        if (value) {
            filterValue = value.toString().toLowerCase();
        } else {
            filterValue = '';
        }
        return this.providers_preference.filter(provider => {
            return provider.name.toLowerCase().includes(filterValue) || provider.email.toLowerCase().includes(filterValue);
        });
    }

    // changes referral status, updates history, saves to firestore, and creates new task if necessary
    async changeReferralStatus(status_name, status_schema, status_details = {}, additional_task = null) {
        const new_status_value = status_schema.value;
        this.addActionToHistory({
            name: status_name,
            status: new_status_value,
            details: status_details,
        });

        // if creating an additional task during another action, add it to history at same time
        if (additional_task) {
            this.addActionToHistory({
                name: 'actions',
                status: 10,
                details: additional_task,
            });
        }

        await this.updateReferralInFirestore(status_name, new_status_value, status_details);
        console.log(`${status_name} status changed to ${new_status_value}`);

        // mark the history and status_name fields as pristine now that we've saved them to firestore
        this.referralFormGroup.get('history').markAsPristine();

        if (status_name !== 'actions') {
            this.referralFormGroup.get(status_name).markAsPristine();
        }

        // TODO this is to catch any odd errors later
        for (const key in this.referralFormGroup.controls) {
            if (this.referralFormGroup.controls[key].dirty) {
                console.warn(`unexpected dirty field after a status/action change! form field key: ${key}`);
            }
        }

        // there are two ways a new task can be created: from an action to create a task or from a side effect of a status change
        const is_task_from_status = additional_task;
        const is_task_from_specific_action = !!(status_name === 'actions' && status_schema.value === 10);
        if (is_task_from_specific_action || is_task_from_status) {
            const task = is_task_from_specific_action ? status_details : additional_task;
            console.log('creating a new task', task);
            await this.referralsService.createNewTask(this.referral.id, task);
        }
    }
    async updateReferralInFirestore(status_name, new_status_value, details) {
        console.log(`updating referral in firestore with ${status_name} status ${new_status_value}`);
        try {
            const updated = firebase.firestore.Timestamp.fromDate(new Date());
            const updated_by_email = this.authService.user.email;
            const updated_by = this.authService.user.displayName;

            const itemDoc = this.afs.collection<any>('referrals').doc(this.referral.id);
            const updatedFields = {
                history: this.referralFormGroup.get('history').value,
                updated_by_email: updated_by_email,
                updated_by: updated_by,
                updated: updated,
            };
            if (status_name !== 'actions') {
                updatedFields[status_name] = new_status_value;
                updatedFields[`${status_name}_${new_status_value}_details`] = {
                    ...details,
                    updated_by_email: updated_by_email,
                    updated_by: updated_by,
                    updated: updated,
                };
            }

            itemDoc.update(updatedFields).then(() => {
                // using await doesn't work here and causes rendering issues for the toast, so using .then and .catch instead
                this.toastsService.showSnackBar('Referral status updated', 'success');
            }).catch(e => {
                throw new Error(e);
            });
        } catch (e) {
            console.warn(e);
            this.toastsService.showSnackBar(e, 'error', 5000);
            throw new Error('Error updating referral in firestore');
        }
    }

    async logAction(action_schema) {
        // TODO when logging calls, SMS, or email, auto-move to Attempting? is "attempting" even useful?

        // only open dialog if there are details fields that can be filled in
        if (!action_schema.details || action_schema.details.length === 0) {
            // update history early because we don't need additional popup details
            // TODO how to have 'action' be a variable instead so we can refactor?
            await this.changeReferralStatus('actions', action_schema, {});
            return; // exit early in this case
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.data = {
            details_schema: action_schema.details,
            show_create_task: action_schema.value !== 10, // only show create task option if action is NOT to create a task
        };
        this.openConfirmDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

        this.openConfirmDialogRef.afterClosed().subscribe(async values => {
            if (values?.details) {
                // update history now that we have the details
                // TODO how to have 'action' be a variable instead so we can refactor?
                await this.changeReferralStatus('actions', action_schema, values?.details, values?.task);
            } else {
                console.log('No Action Added');
            }
        });
    }

    assignToUser() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.data = {
            assigned_to: this.referralFormGroup.get('assigned_to').value,
        };
        this.assignDialogRef = this.dialog.open(AssignDialogComponent, dialogConfig);

        this.assignDialogRef.afterClosed().subscribe(async assigned_to => {
            if (assigned_to !== undefined && assigned_to !== null && this.referralFormGroup.get('assigned_to').value !== assigned_to) {
                this.referralFormGroup.get('assigned_to').patchValue(assigned_to);
                this.addAssignmentToHistory(assigned_to);
                this.referralFormGroup.markAsDirty();
                // TODO if this is saved in the DB should we mark the form as pristine? verify if email sent immediately or on save of popup
                // I think we update formgroup history but until we click save all is lost
                // once this change is done on save, verify that the "discard changes popup" only shows if the basic referral text fields are dirty
                // test that sending sms doesn't make dirty
            }
        });
    }

    async onChangeStatus(statuses_schema, $event) {
        // what status was clicked?
        const status_value = $event.value;
        // get the schema for the status that was clicked
        const status_schema = statuses_schema.statuses.find((status) => status.value === status_value);

        // only open dialog if there are details fields that can be filled in
        if (!status_schema.details || status_schema.details.length === 0) {
            // update history early because we don't need additional popup details
            await this.changeReferralStatus(statuses_schema.name, status_schema, {}, null);
            return; // exit early in this case
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.data = {
            details_schema: status_schema.details,
            details_values: this.referralFormGroup.get(`${statuses_schema.name}_${status_value}_details`).value,
            show_create_task: true,
        };
        this.openConfirmDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

        this.openConfirmDialogRef.afterClosed().subscribe(async values => {
            if (!values?.details) {
                // if no details, undo the status change and mark the form as pristine
                this.referralFormGroup.get(statuses_schema.name).patchValue(statuses_schema['recently_updated_status']);
                if (this.referral[statuses_schema.name] === statuses_schema['previous_status']) {
                    this.referralFormGroup.get(statuses_schema.name).markAsPristine();
                }
                return;
            }

            this.referralFormGroup.get(`${statuses_schema.name}_${status_value}_details`).patchValue(values?.details);

            // persist updates to firestore
            await this.changeReferralStatus(statuses_schema.name, status_schema, values?.details, values?.task);
        });
    }

    sendSingleSMS() {
        this.globalService.saving = true;
        const body = this.smsForm.get('body').value;
        this.referralsService.sendSingleSMS(this.referral, body);
        this.smsForm.reset();
    }

    archiveReferral() {
        // this.referral.archived = true;
        return;
    }

    statusChanged() {
        if (this.referralFormGroup.get('status').value === 'Waitlisted') {
            this.referralFormGroup.patchValue({
                sms_enabled: false,
            });
        } else if (this.referralFormGroup.get('status').value === 'Active' && !DISALLOW_SMS.includes(this.referral.type)) {
            this.referralFormGroup.patchValue({
                sms_enabled: true,
            });
        }
    }

    checkIfUrlInGroupTherapySettings(leader_email, office_code, group_id) {
        const found_setting = this.group_therapy_settings.find(setting => {
            return setting.group_leader_email === leader_email && setting.office_code === office_code && setting.appointment_type_id === group_id;
        });

        if (found_setting) {
            return found_setting.url;
        }

        return null;
    }

    getSchedulingHyperlink(referral) {
        // get scheduling hyperlink based on referral metadata or special group settings url or return basic search hyperlink
        if (referral.type === 'group') {
            const leader_email = referral.group_leader.toLowerCase();

            const url = this.checkIfUrlInGroupTherapySettings(leader_email, referral.group_office_code, referral.group_id);
            if (url) {
                return url;
            }
            return referral.group_leader_profile_url;
        }

        let type = '';
        if (referral.type === 'therapy') {
            type = 'therapy';
        } else if (referral.type === 'psychiatry') {
            type = 'psychiatry';
        }

        let hyperlink = `https://claritychi.com/search?ref=1&treatmentType=${type}`;
        if (referral.insurance !== 'Other') {
            hyperlink += `&insurance=${referral.insurance}`;
        }

        if (referral.primary_specialty) {
            hyperlink += `&specialties=${referral.primary_specialty}`;
        }
        if (referral.secondary_specialty) {
            hyperlink += `%2C${referral.secondary_specialty}`;
        }
        if (referral.treatment_type_preference && referral.treatment_type_preference !== 'Any') {
            if  (referral.treatment_type_preference.includes('minors')) {
                hyperlink += `&patientType=minors`;
                if (referral.dob) {
                    const formatted_dob = moment(referral.dob).format('YYYY-MM-DD');
                    hyperlink += `&dob=${formatted_dob}`;
                }
            } else if (!referral.treatment_type_preference.includes('minors')) {
                hyperlink += `&patientType=${referral.treatment_type_preference}`;
            }
        } else {
            hyperlink += `&patientType=adults`;
        }

        if (referral.medium_preference === 'Face to face') {
            hyperlink += `&isTelemedicine=false`;
        } else {
            hyperlink += `&isTelemedicine=true`;
        }

        // if (referral.location_preference !== 'No preference') {
        //     // TODO need to get proper slug and update these location values and labels
        //     hyperlink += `&locations=${referral.location_preference}`;
        // }

        hyperlink += `&wizard=true`;

        return hyperlink;
    }

    referralSourceChanged() {
        if (this.referralFormGroup.get('source').value === 'internal') {
            this.referralFormGroup.get('referring_provider').setValidators([Validators.required, AutoCompleteValidator.mustBeInSimpleList(this.providers)]);
        } else {
            this.referralFormGroup.get('referring_provider').clearValidators();
        }
        this.referralFormGroup.get('referring_provider').updateValueAndValidity();
    }
    referralChanged(requiredChanged = false) {
        const referral = this.referralFormGroup.getRawValue();
        this.customHyperlink = this.getSchedulingHyperlink(referral);
        if (requiredChanged) {
            if (this.referralFormGroup.get('insurance').value === 'Other') {
                this.referralFormGroup.get('other_insurance').setValidators([Validators.required]);
            } else {
                this.referralFormGroup.get('other_insurance').clearValidators();
            }
            this.referralFormGroup.get('other_insurance').updateValueAndValidity();
        }
    }

    initSubscriptions() {
        this.providersSubscription = this.referralsService.providers$.subscribe(coded_providers => {
            let final_providers = [];
            for (const [code, providers] of Object.entries(coded_providers)) {
                final_providers = final_providers.concat(providers);
                final_providers = final_providers.sort(function(a, b) {
                    if ( a.name < b.name ) {
                        return -1;
                    }
                    if ( a.name > b.name ) {
                        return 1;
                    }
                    return 0;
                });
                // console.log(final_providers);
            }
            final_providers.unshift(
                {email: 'website@claritychi.com', name: 'WEBSITE'},
                {email: 'callcenter@claritychi.com', name: 'CALL CENTER'},
                {email: 'rnfrontdesk@claritychi.com', name: 'RIVER NORTH FRONT DESK'},
                {email: 'lvfrontdesk@claritychi.com', name: 'LAKEVIEW FRONT DESK'},
                {email: 'loopfrontdesk@claritychi.com', name: 'LOOP FRONT DESK'},
                {email: 'evanstonfrontdesk@claritychi.com', name: 'EVANSTON FRONT DESK'},
                {email: 'ahfrontdesk@claritychi.com', name: 'ARLINGTON HEIGHTS FRONT DESK'}
            );
            this.providers = final_providers;
            this.providers_preference = [{name: 'No preference', email: 'No preference'}].concat(this.providers);

            this.referralFormGroup.get('referring_provider').setValidators([Validators.required, AutoCompleteValidator.mustBeInSimpleList(this.providers)]);
            const providerPreferenceControl = this.referralFormGroup.get('provider_preference');
            if (providerPreferenceControl) {
                providerPreferenceControl.setValidators([Validators.required, AutoCompleteValidator.mustBeInSimpleList(this.providers_preference)]);
            }
        });
        this.specialtiesSubscription = this.referralsService.specialties$.subscribe(specialties => {
            this.specialties = specialties;
        });
        this.insurancesSubscription = this.referralsService.insurances$.subscribe(insurances => {
            if (this.referral.type === 'tms') {
                delete insurances['united'];
            }
            this.insurances = insurances;
        });
        this.treatmentTypesSubscription = this.referralsService.treatment_types$.subscribe(treatment_types => {
            this.treatment_types = treatment_types;
        });
        this.groupTherapySettingsSubscription = this.referralsService.group_therapy_settings$.subscribe(settings => {
            this.group_therapy_settings = settings;
        });

        this.appointmentTypeDetailsSubscription = this.referralsService.appointmentTypeDetails$.subscribe(details => {
            this.appointment_type_details = details;
            // console.log(this.appointment_type_details);
            if (this.referral.group_id) {
                for (const [office_code, settings] of Object.entries(this.appointment_type_details)) {
                    if (settings[this.referral.group_id]) {
                        this.selected_appointment_type_group_leaders_emails = settings[this.referral.group_id].group_leaders_emails;
                        break;
                    }
                }
            }
            if (this.referral.group_leader) {
                this.getGroupLeaderInfo(this.referral.group_leader);
            }
            // audit group appt types
            // for (const [id, appt_type] of Object.entries(this.appointment_type_details['133003'])) {
            //     if (appt_type.is_group) {
            //         // @ts-ignore
            //         console.log(appt_type.name, appt_type.group_leaders_emails);
            //     }
            // }
            // for (const [id, appt_type] of Object.entries(this.appointment_type_details['138325'])) {
            //     if (appt_type.is_group) {
            //         // @ts-ignore
            //         console.log(appt_type.name, appt_type.group_leaders_emails);
            //     }
            // }
        });
    }

    groupChanged(appointment_type_details, office_code) {
        this.referralFormGroup.patchValue({
            group_leader: '',
            group_leader_profile_url: '',
            insurance: '',
            group_name: appointment_type_details.name,
            group_office_code: office_code,
        });
        this.selected_appointment_type_group_leaders_emails = appointment_type_details.group_leaders_emails;
    }

    officeCodeChanged(office_code) {
        if (office_code !== '') {
            this.referralFormGroup.get('chart_number').setValidators([Validators.required]);
            this.referralFormGroup.get('patient_id').setValidators([Validators.required]);
        } else {
            this.referralFormGroup.get('chart_number').clearValidators();
            this.referralFormGroup.get('patient_id').clearValidators();
        }
        this.referralFormGroup.get('chart_number').updateValueAndValidity();
        this.referralFormGroup.get('patient_id').updateValueAndValidity();
    }

    getGroupLeaderInfo(group_leader_email) {
        this.globalService.saving = true;
        const url = environment.apiBaseUrl + 'referrals/provider';
        const params = new HttpParams()
            .set('password', 'Clarity9404')
            .set('email', group_leader_email);
        this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
            if (result.status === 200 && result['body']) {
                this.referralFormGroup.patchValue({
                    group_leader_profile_url: result['body']['profileUrl'],
                });
                const asArray = Object.entries(this.insurances);
                const filtered = asArray.filter(([key, value]) => {
                    for (const insurance of result['body']['insurances']) {
                        if (insurance['slug'] === key) {
                            return true;
                        }
                    }
                    return false;
                });
                this.filteredInsurances = Object.fromEntries(filtered);
            } else {
                this.toastsService.showSnackBar('Error loading group leader details from website by email address', 'error', 5000);
            }
            this.referralChanged();
            this.globalService.saving = false;
        }, error => {
            this.toastsService.showSnackBar('Error loading group leader accepted insurances and website URL', 'error', 5000);
            this.referralChanged();
            this.globalService.saving = false;
        });
    }

    groupLeaderChanged(group_leader_email) {
        // group_leader_email
        this.referralFormGroup.patchValue({
            group_leader_profile_url: '',
            insurance: '',
        });
        this.getGroupLeaderInfo(group_leader_email);
    }

    checkForBookedVisitAndSaveReferral() {
        if (this.referralFormGroup.invalid) {
            this.findInvalidControls();
            return;
        }

        this.globalService.saving = true;

        const referral = this.referralFormGroup.getRawValue();

        const url = environment.amdApiBaseUrl + 'lookup-referral-visit';
        const patient_id = referral['patient_id'];
        const referral_type = referral['type'];
        let referral_date;
        if (referral['created']) {
            referral_date = moment(referral['created'].toDate()).format('YYYY-MM-DDThh:mm:ss');
        } else {
            referral_date = moment().format('YYYY-MM-DDThh:mm:ss');
        }
        const office_code = referral['office_code'];

        // if missing required fields, or is a waitlisted patient, save and continue
        if (!patient_id || !referral_type || !referral_date || !office_code || referral['status'] === 'Waitlisted' || referral['type'] === 'php-iop') {
            console.log('Skipping check for booked visit');
            // TODO skip TMS too?
            this.saveReferral();
        } else {
            // attempt to find booked visit
            let params = new HttpParams()
                .set('patient_id', patient_id)
                .set('referral_type', referral_type)
                .set('referral_date', referral_date)
                .set('office_code', office_code);

            if (referral_type === 'group') {
                params = params.set('referral_appt_type_id', referral['group_id']);
            }

            this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
                if (result.status === 200) {
                    const referral_details = result['body']['referral_visit'];
                    console.log('Found a booked visit!', referral_details);
                    this.toastsService.showSnackBar('Patient has an upcoming visit already scheduled. Saving and archiving referral.', 'success', 5000);
                    this.referralFormGroup.patchValue({
                        referral_visit: referral_details,
                        status: 'Booked',
                    });
                    this.saveReferral();
                }
            }, error => {
                console.log(error);
                if (referral['patient_id_in_alternate_office_code']) {
                    let alternate_office_code;
                    if (office_code === '133003') {
                        alternate_office_code = '138325';
                    } else {
                        alternate_office_code = '133003';
                    }
                    let alternate_params = new HttpParams()
                        .set('patient_id', referral['patient_id_in_alternate_office_code'])
                        .set('referral_type', referral_type)
                        .set('referral_date', referral_date)
                        .set('office_code', alternate_office_code);

                    if (referral_type === 'group') {
                        alternate_params = alternate_params.set('referral_appt_type_id', referral['group_id']);
                    }

                    this.http.get(url, {observe : 'response', params: alternate_params}).subscribe(result => {
                        if (result.status === 200) {
                            const referral_details = result['body']['referral_visit'];
                            console.log('Found a booked visit!', referral_details);
                            this.toastsService.showSnackBar('Patient has an upcoming visit already scheduled. Saving and archiving referral.', 'success', 5000);
                            this.referralFormGroup.patchValue({
                                referral_visit: referral_details,
                                status: 'Booked',
                            });
                            this.saveReferral();
                        }
                    }, alternate_error => {
                        console.log(alternate_error);
                        this.saveReferral();
                    });
                } else {
                    this.saveReferral();
                }
            });
        }
    }

    lookupPatientDetails() {
        const office_code = this.referralFormGroup.get('office_code').value;
        const chart_number = this.referralFormGroup.get('chart_number').value;
        const first_name = this.referralFormGroup.get('first_name').value;
        const last_name = this.referralFormGroup.get('last_name').value;
        const dob = this.referralFormGroup.get('dob').value;

        if ((office_code && chart_number) || (office_code && first_name && last_name && dob)) {
            this.globalService.saving = true;

            let url;
            let params;

            if (office_code && chart_number) {
                url = environment.amdApiBaseUrl + 'lookup-patient-by-chart-number';
                params = new HttpParams()
                    .set('chart_number', chart_number)
                    .set('office_code', office_code);
            } else if (office_code && first_name && last_name && dob) {
                url = environment.amdApiBaseUrl + 'lookup-patient-by-name-and-dob';
                params = new HttpParams()
                    .set('first_name', first_name)
                    .set('last_name', last_name)
                    .set('dob', dob)
                    .set('office_code', office_code);
            }


            this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
                if (result.status === 200) {
                    const patient_details = result['body']['patient_details'];
                    if (patient_details['first_name'] && patient_details['last_name'] && patient_details['patient_id']) {
                        const parsedPhone = phoneUtil.parseAndKeepRawInput(patient_details['phone'], 'US');
                        const number = phoneUtil.format(parsedPhone, PNF.E164);

                        this.referralFormGroup.patchValue({
                            first_name: patient_details['first_name'],
                            last_name: patient_details['last_name'],
                            patient_id: patient_details['patient_id'],
                            dob: patient_details['dob'],
                            chart_number: patient_details['chart'],
                            phone: number,
                        });
                        this.toastsService.showSnackBar('Patient details updated with match from AMD', 'success', 5000);
                    } else {
                        this.referralFormGroup.patchValue({
                            first_name: '',
                            last_name: '',
                            patient_id: '',
                            phone: '',
                        });
                        this.toastsService.showSnackBar('No patient found with chart number: ' + chart_number, 'error', 5000);
                    }
                    this.lookupPatientDetailsInAnotherOfficeCode();
                }
            }, error => {
                console.log(error);
                this.globalService.saving = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } else {
            this.toastsService.showSnackBar('Looking up a patient requires their chart number or their name and dob', 'error', 5000);
        }
    }

    lookupPatientDetailsInAnotherOfficeCode() {
        let office_code = this.referralFormGroup.get('office_code').value;
        const first_name = this.referralFormGroup.get('first_name').value;
        const last_name = this.referralFormGroup.get('last_name').value;
        const dob = this.referralFormGroup.get('dob').value;

        if (office_code && first_name && last_name && dob) {
            this.globalService.saving = true;

            let url;
            let params;

            if (office_code === '133003') {
                office_code = '138325';
            } else {
                office_code = '133003';
            }

            url = environment.amdApiBaseUrl + 'lookup-patient';
            params = new HttpParams()
                .set('first_name', first_name)
                .set('last_name', last_name)
                .set('dob', dob)
                .set('office_code', office_code);

            this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
                console.log('Looking in other office key for patient...');
                if (result.status === 200) {
                    const patient_id = result['body']['patient_id'];
                    if (patient_id) {
                        this.referralFormGroup.patchValue({
                            patient_id_in_alternate_office_code: patient_id,
                        });
                        this.toastsService.showSnackBar('Patient found in other office key', 'success', 5000);
                    } else {
                        this.referralFormGroup.patchValue({
                            patient_id_in_alternate_office_code: null,
                        });
                    }
                    this.globalService.saving = false;
                }
            }, error => {
                console.log(error);
                this.referralFormGroup.patchValue({
                    patient_id_in_alternate_office_code: null,
                });
                this.globalService.saving = false;
            });
        } else {
            this.globalService.saving = false;
            this.toastsService.showSnackBar('Looking up a patient in alternate key requires their name and dob', 'error', 5000);
        }
    }

    hasStatusChanged(status_name) {
        return this.referralFormGroup.get(status_name).value !== this.referral[status_name];
    }

    addActionToHistory(action) {
        // add a history record to this.referralFormGroup.get('history'), record should also include changed_by and changed_at
        // patch value for history to avoid updating referenced array
        this.referralFormGroup.patchValue({
            history: [...this.referralFormGroup.get('history').value,
                {
                    action: {
                        ...action,
                        pretty_printed: this.referralsService.prettyPrintPhpIopAction(action),
                    },
                    changed_by: this.authService.user.displayName,
                    changed_at: firebase.firestore.Timestamp.fromDate(new Date()),
                }
            ]
        });
    }

    addAssignmentToHistory(assigned_to) {
        this.referralFormGroup.patchValue({
            history: [...this.referralFormGroup.get('history').value,
                {
                    action: {
                        pretty_printed: this.referralsService.prettyPrintAssignedTo(assigned_to),
                    },
                    changed_by: this.authService.user.displayName,
                    changed_at: firebase.firestore.Timestamp.fromDate(new Date()),
                }
            ]
        });
    }

    prettyPrintActions() {
        const history = this.referralFormGroup.get('history').value;
        const history_with_updated_pretty_printed_actions = [];
        for (let history_record of history) {
            history_record = {
                ...history_record,
                action: {
                    ...history_record.action,
                    'pretty_printed': this.referralsService.prettyPrintPhpIopAction(history_record.action),
                },
            };
            history_with_updated_pretty_printed_actions.push(history_record);
        }

        this.referralFormGroup.patchValue({
            history: history_with_updated_pretty_printed_actions,
        });
    }

    saveReferral() {
        try {
            this.globalService.saving = true;

            const created_by = this.authService.user.displayName;
            const created_by_email = this.authService.user.email;

            const referral = this.referralFormGroup.getRawValue();

            referral['updated_by'] = created_by;
            referral['updated_by_email'] = created_by_email;
            referral['updated'] = firebase.firestore.Timestamp.fromDate(new Date());

            if (!referral['created']) {
                referral['created_by'] = created_by;
                referral['created_by_email'] = created_by_email;
                referral['created'] = firebase.firestore.Timestamp.fromDate(new Date());
            }

            const parsedPhone = phoneUtil.parseAndKeepRawInput(referral['phone'], 'US');
            const number = phoneUtil.format(parsedPhone, PNF.E164);
            referral['phone'] = number;

            if (referral.status === 'Waitlisted' && this.referral.status !== 'Waitlisted') {
                referral['waitlisted_on'] = new Date();
            }

            if (referral.status !== 'Waitlisted' && this.referral.status === 'Waitlisted') {
                referral['unwaitlisted_on'] = new Date();
            }

            console.log(referral);

            this.saveReferralToFirestore(referral);
            this.globalService.saving = false;
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    findInvalidControls() {
        const invalid = [];
        const controls = this.referralFormGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log(invalid);
    }

    loadReferralMessages() {
        if (this.referral.id) {
            const referralMessagesCollection = this.afs.collection<any>('referrals').doc(this.referral.id).collection('messages', ref => ref.orderBy('date_created'));
            referralMessagesCollection.valueChanges({idField: 'id'}).subscribe((messages) => {
                this.referral.messages = messages;
                console.log('SMS Messages:', this.referral.messages);
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
        }
    }

    loadReferralTasks() {
        if (this.referral.id) {
            this.loadingTasks = true;
            const taskCollection = this.afs.collection<any>('referrals').doc(this.referral.id).collection('tasks', ref => ref.orderBy('due_date', 'desc'));
            taskCollection.valueChanges({idField: 'id'}).subscribe((tasks) => {
                this.referral.tasks = tasks;
                this.loadingTasks = true;
                this.tasksDataSource.data = []; // TODO what happens if this is omitted?
                this.tasksDataSource.data = tasks;
                this.loadingTasks = false;
                // this.tasksDataSource.sort = this.sort;
                console.log('Tasks:', this.referral.tasks);
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
                this.loadingTasks = false;
            });
        }
    }

    saveReferralToFirestore(referral) {
        const batch = this.afs.firestore.batch();
        let id = this.referral?.id;
        if (!id) {
            id = this.afs.createId();
        }
        const referralsRef = this.afs.firestore.doc('referrals/' + id);
        batch.set(referralsRef, referral, {merge: true});

        if (referral.status === 'Declined' && this.referral.status !== 'Declined') {
            let pid = referral.chart_number;
            if (!pid) {
                pid = referral.first_name.substring(0, 1).toUpperCase() + referral.last_name.substring(0, 1).toUpperCase();
            }
            const newEmailRef = this.afs.firestore.collection('mail').doc();
            const email_data = {
                to: referral.referring_provider,
                from: 'Clarity Clinic <noreply@claritychi.com>',
                replyTo: 'Clarity Clinic <noreply@claritychi.com>',
                template: {
                    name: 'referral-declined',
                    data: {
                        subject: `#${pid} has declined your referral`,
                        referral_description: `Patient #${pid} referred for ${referral.type} has been marked declined by ${referral.updated_by}`,
                    }
                }
            };
            batch.set(newEmailRef, email_data);
        }

        batch.commit().then(() => {
            this.globalService.saving = false;
            console.warn('DONE SAVING TO FIRESTORE!');
            this.referralFormGroup.markAsPristine();
            this.closeDialog();
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onDebug(): void {
        console.log(this.referralFormGroup.getRawValue());
        console.log(this.referralFormGroup.dirty);
    }

    closeDialog() {
        // checking for unsaved changes
        if (this.referralFormGroup.dirty) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.autoFocus = false;
            this.unsavedChangesDialogRef = this.dialog.open(UnsavedChangesDialogComponent, dialogConfig);
            this.unsavedChangesDialogRef.afterClosed().subscribe(async confirmed => {
                if (confirmed) {
                    this._originalClose.bind(this.dialogRef)();
                }
            });
        } else {
            this._originalClose.bind(this.dialogRef)();
        }
    }

    getMessageDate(message_date) {
        return moment(message_date.toDate()).format('llll');
    }

    // Workaround for angular component issue #13870
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

    ngOnChanges() {
        // if (!this.initialized) {
        //     return;
        // }
    }

    ngOnDestroy() {
        this.providersSubscription.unsubscribe();
        this.appointmentTypeDetailsSubscription.unsubscribe();
        this.specialtiesSubscription.unsubscribe();
        this.insurancesSubscription.unsubscribe();
        this.treatmentTypesSubscription.unsubscribe();
        this.groupTherapySettingsSubscription.unsubscribe();
    }
}
