export const primaryDiagnoses = [
    'F32.1 MDD single episode, moderate',
    'F33.1 MDD recurrent, moderate, w/out psychosis',
    'F32.2 MDD single episode, severe',
    'F33.2 MDD recurrent, severe, w/out psychosis',
    'F42 Obsessive-compulsive disorder',
    'F42.2 Mixed obsessional thoughts and acts',
    'F42.3 Hoarding disorder',
    'F42.4 Excoriation (skin-picking) disorder',
    'F42.8 Other obsessive-compulsive disorder',
    'F42.9 Obsessive-compulsive disorder, unspecified',
];

export const primaryDiagnosesWithInsurance = [
    'F32.1 MDD single episode, moderate',
    'F33.1 MDD recurrent, moderate, w/out psychosis',
    'F32.2 MDD single episode, severe',
    'F33.2 MDD recurrent, severe, w/out psychosis',
];
