import { FormControl } from '@angular/forms';

export class AutoCompleteValidator {
    static mustBeInList = (list: Array<any>, key: string) => {
        return (control: FormControl) => {
            let isInside: boolean;
            const valueToCompare: string | boolean =
                typeof control['value'] === 'object'
                    ? control['value'][key].toLowerCase()
                    : typeof control['value'] === 'string'
                    ? control['value'].toLowerCase()
                    : false;

            isInside = list.some(entry => {
                const currentValue: string = entry[key].toLowerCase();
                return currentValue === valueToCompare;
            });
            if (isInside) {
                return null;
            } else {
                return { mustBeInList: { valid: false } };
            }
        };
    }

    static mustBeInSimpleList = (list: Array<any>) => {
        return (control: FormControl) => {
            let isInside: boolean;
            const valueToCompare: string | boolean =
                typeof control['value'] === 'object'
                    ? control['value'].toLowerCase()
                    : typeof control['value'] === 'string'
                    ? control['value'].toLowerCase()
                    : false;

            isInside = list.some(entry => {
                const currentValue: string = entry.email.toLowerCase();
                return currentValue === valueToCompare;
            });
            if (isInside) {
                return null;
            } else {
                return { mustBeInList: { valid: false } };
            }
        };
    }
}

