import {Component, OnInit} from '@angular/core';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
    }

    successCallback(data: FirebaseUISignInSuccessWithAuthResult) {
        if (data.authResult.user) {
            localStorage.setItem('user', JSON.stringify(data.authResult.user));
        }

        const path = this.route.snapshot.url[1].path;
        if (path === 'projects') {
            const id = this.route.snapshot.paramMap.get('id');
            const taskId = this.route.snapshot.paramMap.get('taskId');
            if (taskId && id) {
                this.router.navigate([path, id, taskId]);
            } else if (id) {
                this.router.navigate([path, id]);
            }
        } else if (path === 'recruiting') {
            const type = this.route.snapshot.paramMap.get('type');
            if (type) {
                this.router.navigate([path, type]);
            } else {
                this.router.navigate([path]);
            }
        } else if (path === 'referrals') {
            const id = this.route.snapshot.paramMap.get('id');
            if (id) {
                this.router.navigate([path, id]);
            } else {
                this.router.navigate([path]);
            }
        } else {
            this.router.navigate(['home']);
        }

        // since we only allow Google logins, no need to verify email
        // if (data.authResult.additionalUserInfo.isNewUser) {
        //     console.log('New sign in, sending verification');
        //     data.authResult.user.sendEmailVerification();
        // }
    }

    errorCallback(data: FirebaseUISignInFailure) {
        console.warn('errorCallback', data);
    }

}
