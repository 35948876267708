import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReferralsService} from '../../services/referrals.service';

@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent implements OnInit, OnChanges {

    initialized = false;

    @Output() taskFormGroupChanged = new EventEmitter<any>();

    taskFormGroup: FormGroup;

    constructor(private _formBuilder: FormBuilder, public referralsService: ReferralsService) {
    }

    ngOnInit() {
        this.taskFormGroup = this._formBuilder.group({
            type: ['To-Do', Validators.required],
            due_date: [this.referralsService.dueDateOptions[2].value, Validators.required],
            notes: [''],
            completed: [false],
        });

        this.taskFormGroup.valueChanges
            .subscribe(() => this.taskFormGroupChanged.emit(this.taskFormGroup.value));
        this.initialized = true;
    }

    ngOnChanges() {
        if (!this.initialized) {
            return;
        }

        // TODO should we disable form as parent component checkbox changes?
        // if (this.disabled) {
        //     this.taskFormGroup.disable();
        // } else {
        //     this.taskFormGroup.enable();
        // }
    }
}
