import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-stage-filter-button',
    templateUrl: './stage-filter-button.component.html',
    styleUrls: ['./stage-filter-button.component.scss']
})
export class StageFilterButtonComponent implements OnInit, OnChanges {

    @Input() name;
    @Input() label;
    @Input() statuses = {};
    @Input() selectedFilters = {};
    @Input() description = 'Stay on top of the referrals you\'re currently working';

    selectedStatus = null;

    @Output() setFilter = new EventEmitter<any>();

    constructor() { }

    selectStatus(value) {
        this.selectedStatus = value;
        this.setFilter.emit({
            name: this.name,
            value: value
        });
    }

    ngOnInit() {
        if (this.selectedFilters[this.name]) {
            this.selectedStatus = this.selectedFilters[this.name];
        }
    }

    ngOnChanges() {
        if (this.selectedFilters[this.name]) {
            this.selectedStatus = this.selectedFilters[this.name];
        }
    }
}
