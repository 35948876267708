import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ToastsService} from './toasts.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    private readonly _membershipsLoaded = new BehaviorSubject<boolean>(false);
    readonly membershipsLoaded$ = this._membershipsLoaded.asObservable();

    get membershipsLoaded(): boolean {
        return this._membershipsLoaded.getValue();
    }

    set membershipsLoaded(val: boolean) {
        this._membershipsLoaded.next(val);
    }

    groups = {};

    currentUsersGroups = [];

    allUsers = [];
    allUsersMap = {};
    allUsersEmailMap = {};

    userPhotoURL;

    constructor(private toastsService: ToastsService, private afs: AngularFirestore) { }

    initGroupsService(user_email, uid) {
        this.getUsers(uid);
        this.getGroups(user_email);
        this.getActiveGroups();
    }

    getGroupColorIndex(email) {
        const group = this.groups[email];
        if (group) {
            const color = group['color'];
            if (color !== undefined) {
                return color;
            }
        }
        return null;
    }

    getGroupName(email) {
        const group = this.groups[email];
        if (group) {
            const name = group['displayName'];
            if (name !== undefined) {
                return name;
            }
        }
        return null;
    }

    getActiveGroupsAsList() {
        return Object.keys(this.groups);
    }

    getGroupsAssignedToProject(project_tasks) {
        const groupsSet = new Set(project_tasks.map(item => item.assigned_to));
        return Array.from(groupsSet);
    }

    getGroups(user_email) {
        const userGroupsCollection = this.afs.collection<any>('microsoft-groups', ref => ref.where('members', 'array-contains', user_email));
        userGroupsCollection.valueChanges().subscribe((groups) => {
            console.warn(groups);
            const groups_list = groups.map(group => group['email']);
            this.currentUsersGroups = groups_list;
            this.membershipsLoaded = true;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    getActiveGroups() {
        const groupsCollection = this.afs.collection<any>('microsoft-groups', ref => ref.orderBy('email').where('active', '==', true));
        groupsCollection.valueChanges().subscribe((groups) => {
            const active_groups = groups.filter(group => group['active']);
            const reformatted_active_groups = {};
            let i = 0;
            for (const group of active_groups) {
                group.color = i;
                reformatted_active_groups[group.email] = group;
                i++;
            }
            console.log(reformatted_active_groups);
            this.groups = reformatted_active_groups;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    getUsers(uid) {
        const usersCollection = this.afs.collection<any>('users');
        usersCollection.valueChanges().subscribe((users) => {
            this.allUsers = users;
            // console.log(this.allUsers);
            const allUsersMap = {};
            const allUsersEmailMap = {};
            for (const user of users) {
                allUsersMap[user.uid] = user;
                allUsersEmailMap[user.email] = user;
            }
            this.allUsersMap = allUsersMap;
            this.allUsersEmailMap = allUsersEmailMap;
            this.userPhotoURL = this.getUserAvatar(uid);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    getUserAvatar(uid: string) {
        if (uid) {
            return this.allUsersMap[uid]?.photoURL ?? null;
        }
        return null;
    }

}
