import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {ToastsService} from './toasts.service';
import {AngularFireFunctions} from '@angular/fire/functions';


@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    private readonly _templates = new BehaviorSubject<[]>([]);
    readonly templates$ = this._templates.asObservable();

    get templates(): [] {
        return this._templates.getValue();
    }

    set templates(val: []) {
        this._templates.next(val);
    }

    private readonly _newTemplates = new BehaviorSubject<[]>([]);
    readonly newTemplates$ = this._newTemplates.asObservable();

    get newTemplates(): [] {
        return this._newTemplates.getValue();
    }

    set newTemplates(val: []) {
        this._newTemplates.next(val);
    }

    locations = [
        'Loop',
        'River North',
        'Lakeview - Broadway',
        'Lakeview - Belmont',
        'Arlington Heights',
        'Evanston',
        'Oakbrook',
        'Mokena'
    ];

    tmsLocations = [
        'Loop',
        'Arlington Heights',
    ];

    phpIopLocations = [
        'Loop',
        'Arlington Heights',
        'Evanston',
        'Mokena'
    ];

    licenses = {
        'Any': [
            'N/A',
            'MD',
            'DO',
            'PA-C',
            'PsyD',
            'PsyD (PD)',
            'AMFT',
            'LMFT',
            'MFT',
            'LCSW',
            'LSW',
            'MSW',
            'LCPC',
            'LPC',
            'MA',
            'MS',
            'NP',
        ],
        'Onboarding - Admin': [
            'N/A'
        ],
        'Onboarding - Admin (Suburbs)': [
            'N/A'
        ],
        'Onboarding - Licensed Therapist': [
            'N/A',
            'PhD',
            'PsyD',
            'AMFT',
            'LMFT',
            'LCSW',
            'LSW',
            'LCPC',
            'LPC'
        ],
        'Onboarding - Licensed Therapist (Suburbs)': [
            'N/A',
            'PhD',
            'PsyD',
            'AMFT',
            'LMFT',
            'LCSW',
            'LSW',
            'LCPC',
            'LPC'
        ],
        'Onboarding - Non-Licensed Therapist': [
            'N/A',
            'PhD (PD)',
            'PsyD (PD)',
            'AMFT',
            'MFT',
            'MSW',
            'MA',
            'MS'
        ],
        'Onboarding - Non-Licensed Therapist (Suburbs)': [
            'N/A',
            'PhD (PD)',
            'PsyD (PD)',
            'AMFT',
            'MFT',
            'MSW',
            'MA',
            'MS'
        ],
        'Onboarding - Existing Provider': [
            'N/A',
            'MD',
            'DO',
            'PA-C',
            'PsyD',
            'PsyD (PD)',
            'AMFT',
            'LMFT',
            'MFT',
            'LCSW',
            'LSW',
            'MSW',
            'LCPC',
            'LPC',
            'MA',
            'MS',
        ],
        'Offboarding - Provider': [
            'N/A',
            'MD',
            'DO',
            'PA-C',
            'PsyD',
            'PsyD (PD)',
            'AMFT',
            'LMFT',
            'MFT',
            'LCSW',
            'LSW',
            'MSW',
            'LCPC',
            'LPC',
            'MA',
            'MS',
            'NP',
        ],
        'Onboarding - Prescriber': [
            'MD',
            'DO',
            'PA-C',
            'NP',
        ],
        'Onboarding - Prescriber (Suburbs)': [
            'MD',
            'DO',
            'PA-C',
            'NP',
        ],
    };

    titles = {
        'Any': [
            'N/A',
            'Clinical Intern',
            'Clinical Intern - Testing',
            'Therapist I (Masters)',
            'Therapist I (Partially Licensed)',
            'Therapist II (Post Doc)',
            'Therapist II (Fully Licensed)',
            'Therapist III (Psychologist)',
            'Clinical Supervisor',
            'Supervising Psychologist',
            'Director',
            'Psychiatrist',
            'Physician Assistant',
        ],
        'Onboarding - Admin': [
            'N/A',
        ],
        'Onboarding - Admin (Suburbs)': [
            'N/A',
        ],
        'Onboarding - Licensed Therapist': [
            'Therapist I (Partially Licensed)',
            'Therapist II (Fully Licensed)',
            'Therapist III (Psychologist)',
            'Clinical Supervisor',
            'Supervising Psychologist',
            'Director',
        ],
        'Onboarding - Licensed Therapist (Suburbs)': [
            'Therapist I (Partially Licensed)',
            'Therapist II (Fully Licensed)',
            'Therapist III (Psychologist)',
            'Clinical Supervisor',
            'Supervising Psychologist',
            'Director',
        ],
        'Onboarding - Non-Licensed Therapist': [
            'Clinical Intern',
            'Clinical Intern - Testing',
            'Therapist I (Masters)',
            'Therapist II (Post Doc)',
        ],
        'Onboarding - Non-Licensed Therapist (Suburbs)': [
            'Clinical Intern',
            'Clinical Intern - Testing',
            'Therapist I (Masters)',
            'Therapist II (Post Doc)',
        ],
        'Onboarding - Existing Provider': [
            'Therapist I (Partially Licensed)',
            'Therapist II (Fully Licensed)',
            'Therapist III (Psychologist)',
            'Clinical Supervisor',
            'Supervising Psychologist',
            'Director',
            'Psychiatrist',
            'Physician Assistant',
        ],
        'Offboarding - Provider': [
            'Clinical Intern',
            'Clinical Intern - Testing',
            'Therapist I (Masters)',
            'Therapist I (Partially Licensed)',
            'Therapist II (Post Doc)',
            'Therapist II (Fully Licensed)',
            'Therapist III (Psychologist)',
            'Clinical Supervisor',
            'Supervising Psychologist',
            'Director',
            'Psychiatrist',
            'Physician Assistant',
            'Nurse Practitioner',
        ],
        'Onboarding - Prescriber': [
            'Psychiatrist',
            'Physician Assistant',
            'Nurse Practitioner',
        ],
        'Onboarding - Prescriber (Suburbs)': [
            'Psychiatrist',
            'Physician Assistant',
            'Nurse Practitioner',
        ],
    };

    // TODO Departments
    departments = [
        'Therapy',
        'Psychiatry',
        'Patient Care',
        'Operations',
        'Marketing',
        'Reimbursement',
        'Information Technology',
        'Human Resources',
        'Finance',
        'Leadership',
        'Other',
    ];

    constructor(private cloudFunctions: AngularFireFunctions, private toastsService: ToastsService, private http: HttpClient) {
        this.getTemplates();
        this.getNewTemplates();
    }

    getTemplates() {
        this.templates = [];
        const url = environment.apiBaseUrl + 'project-templates';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.templates = result.body;
                console.log(this.templates);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading templates');
        });
    }

    getNewTemplates() {
        this.newTemplates = [];
        const url = environment.apiBaseUrl + 'new-project-templates';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.newTemplates = result.body;
                console.log(this.newTemplates);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading new templates');
        });
    }

    deleteAtPath(path) {
        const deleteFn = this.cloudFunctions.httpsCallable('recursiveDelete');
        deleteFn({ path: path, password: 'Clarity9404!' }).subscribe(result => {
            console.log(result);
        }, error => {
            console.log(error);
        });
    }

}
