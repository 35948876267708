import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../../services/toasts.service';
import {GlobalService} from '../../../services/global.service';
import {GroupsService} from '../../../services/groups.service';

@Component({
    selector: 'app-quick-task-dialog',
    templateUrl: 'quick-task-dialog.html',
    styleUrls: ['quick-task-dialog.scss']
})
export class QuickTaskDialogComponent implements OnInit {

    project;
    lane;
    taskFormGroup: FormGroup;


    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        public groupsService: GroupsService,
        public globalService: GlobalService,
        private projectsService: ProjectsService,
        public dialogRef: MatDialogRef<QuickTaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.project = data.project || null;
        this.lane = data.lane || null;
    }

    ngOnInit() {
        console.log(this.project);
        if (this.project) {
            this.taskFormGroup = this._formBuilder.group({
                lane: [this.lane, Validators.required],
                name: [null, Validators.required],
                description: [null, Validators.required],
                assigned_to: [null, Validators.required],
                due_date: [null, Validators.required],
            });
        } else {
            this.toastsService.showSnackBar('No project data found, please try again', 'error');
            this.onNoClick();
        }
    }

    generateTask() {
        const task = this.taskFormGroup.getRawValue();
        task['project_id'] = this.project.id;
        task['completed_by'] = '';
        task['completed'] = false;
        task['order'] = 9999;
        task['relative_due_date'] = 0;
        task['first_name'] = this.project.first_name;
        task['last_name'] = this.project.last_name;
        task['first_name_lowercase'] = this.project.first_name.toLowerCase();
        task['last_name_lowercase'] = this.project.last_name.toLowerCase();
        return task;
    }

    save() {
        const task = this.generateTask();
        console.log(task);
        this.globalService.saving = true;

        const projectsRef = this.afs.firestore.doc('projects/' + this.project.id);
        const tasksRef = projectsRef.collection('tasks').doc();
        tasksRef.set(task).then(() => {
            this.globalService.saving = false;
            this.closeDialog(task);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(task = null) {
        this.dialogRef.close(task);
    }

}
