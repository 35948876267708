import {GlobalService} from '../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../services/toasts.service';
import {MatDialog} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ProjectsService} from '../services/projects.service';
import {GroupsService} from '../services/groups.service';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

    groups;

    constructor(private router: Router,
                public globalService: GlobalService,
                private groupsService: GroupsService,
                private projectsService: ProjectsService,
                public dialog: MatDialog,
                private http: HttpClient,
                private afs: AngularFirestore,
                private toastsService: ToastsService) {
    }

    ngOnInit() {
        const groupsCollection = this.afs.collection<any>('microsoft-groups');
        groupsCollection.valueChanges().subscribe((groups) => {
            console.log(groups);
            const groups_list = groups.map(group => {
                if (!group['displayName']) {
                    group['displayName'] = '';
                }
                return group;
            });
            this.groups = groups_list;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    saveGroupDisplayName(group) {
        try {
            const itemDoc = this.afs.doc<any>('microsoft-groups/' + group.email);
            itemDoc.update({'displayName': group.displayName});
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    saveGroupToggle(group) {
        try {
            const itemDoc = this.afs.doc<any>('microsoft-groups/' + group.email);
            itemDoc.update({'active': !group['active']});
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

}
