import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    private readonly _saving = new BehaviorSubject<boolean>(false);
    readonly saving$ = this._saving.asObservable();

    get saving(): boolean {
        return this._saving.getValue();
    }

    set saving(val: boolean) {
        this._saving.next(val);
    }

    private readonly _searchSettings = new BehaviorSubject<any>({});
    readonly searchSettings$ = this._searchSettings.asObservable();

    get searchSettings(): any {
        return this._searchSettings.getValue();
    }

    set searchSettings(val: any) {
        this._searchSettings.next(val);
    }

    constructor() {
    }

    getCurrentDateTimeString(dateTimeString: string = 'YYYY-MM-DD') {
        return moment().format(dateTimeString);
    }

}
