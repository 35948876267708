import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsService} from './toasts.service';
import * as moment from 'moment';
import {AngularFireFunctions} from '@angular/fire/functions';
import {GlobalService} from './global.service';
import {FirebaseService} from './firebase.service';


@Injectable({
    providedIn: 'root'
})
export class ReferralsService {

    private readonly _providers = new BehaviorSubject<{}>({});
    readonly providers$ = this._providers.asObservable();

    get providers(): {} {
        return this._providers.getValue();
    }

    set providers(val: {}) {
        this._providers.next(val);
    }

    private readonly _specialties = new BehaviorSubject<{}>({});
    readonly specialties$ = this._specialties.asObservable();

    get specialties(): {} {
        return this._specialties.getValue();
    }

    set specialties(val: {}) {
        this._specialties.next(val);
    }

    private readonly _treatment_types = new BehaviorSubject<any[]>([]);
    readonly treatment_types$ = this._treatment_types.asObservable();

    get treatment_types() {
        return this._treatment_types.getValue();
    }

    set treatment_types(val) {
        this._treatment_types.next(val);
    }

    private readonly _insurances = new BehaviorSubject<{}>({});
    readonly insurances$ = this._insurances.asObservable();

    get insurances(): {} {
        return this._insurances.getValue();
    }

    set insurances(val: {}) {
        this._insurances.next(val);
    }

    private readonly _appointmentTypeDetails = new BehaviorSubject<{}>({});
    readonly appointmentTypeDetails$ = this._appointmentTypeDetails.asObservable();

    get appointmentTypeDetails(): {} {
        return this._appointmentTypeDetails.getValue();
    }

    set appointmentTypeDetails(val: {}) {
        this._appointmentTypeDetails.next(val);
    }

    private readonly _group_therapy_settings = new BehaviorSubject<any[]>([]);
    readonly group_therapy_settings$ = this._group_therapy_settings.asObservable();

    get group_therapy_settings() {
        return this._group_therapy_settings.getValue();
    }

    set group_therapy_settings(val) {
        this._group_therapy_settings.next(val);
    }

    office_codes = ['133003', '138325'];

    types = [
        {
            label: 'Therapy',
            value: 'therapy'},
        {
            label: 'Psychiatry',
            value: 'psychiatry'
        },
        {
            label: 'TMS',
            value: 'tms'
        },
        {
            label: 'PHP/IOP',
            value: 'php-iop'
        },
        {
            label: 'Group',
            value: 'group'
        }
    ];

    phpIopSubstatuses = [
        'Pending Insurance',
        'Insurance Verified',
        'In Progress',
        'Pre-Auth Submitted',
        'Assessment Scheduled',
        'Patient Admitted',
    ];

    tmsSubstatuses = [
        'Medical Hx Gathered',
        'PA Issued',
        'Educational Session Scheduled',
    ];

    constructor(private globalService: GlobalService, private cloudFunctions: AngularFireFunctions, private toastsService: ToastsService, private http: HttpClient, private firebaseService: FirebaseService) {
        this.getProviders();
        this.getProviderFields();
        this.getAppointmentTypeDetails();
        this.getGroupTherapySettings();
    }

    getProviders() {
        this.providers = {};
        for (const code of this.office_codes) {
            this.providers[code] = [];
            this.getProvidersAtOfficeCode(code);
        }
    }

    getProviderFields() {
        this.specialties = {};
        this.insurances = {};
        this.treatment_types = [];
        const url = environment.apiBaseUrl + 'referrals/filters';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                this.specialties = result['body']['specialties'].reduce(
                    (obj, item) => Object.assign(obj, { [item.slug]: item.label }), {});
                this.insurances = result['body']['insurances'].reduce(
                    (obj, item) => Object.assign(obj, { [item.slug]: item.label }), {});
                console.log(this.specialties, this.insurances);
                delete this.insurances['humana-ppo-epo'];
                delete this.insurances['tricare'];
                this.treatment_types = this.getTreatmentTypes();
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading treatment areas');
        });
    }

    getAppointmentTypeDetails() {
        this.appointmentTypeDetails = {};
        const apptTypes = this.firebaseService.firebaseAmd.collection<any>('amd').doc('appointment-types-details');
        apptTypes.valueChanges().subscribe((appt_types) => {
            this.appointmentTypeDetails = appt_types;
            this.findGroupLeaders();
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    findGroupLeaders() {
        for (const [office_code, settings] of Object.entries(this.appointmentTypeDetails)) {
            for (const [appointment_type_id, appointment_type_settings] of Object.entries(settings)) {
                if (appointment_type_settings['is_group']) {
                    appointment_type_settings['group_leaders_emails'] = [];
                    for (const provider of this.providers[office_code]) {
                        if (appointment_type_settings['group_leaders'].includes(provider['profile_id'])) {
                            appointment_type_settings['group_leaders_emails'].push(provider['email']);
                        }
                    }
                }
            }
        }
    }

    getTreatmentTypes() {
        return [
            {'adults': 'Adults'},
            {'minors': 'Minors'},
            {'minors-adolescents-3': '(DO NOT USE AFTER 10/2023) Minors/Adolescents (Ages 3-17)'},
            {'minors-adolescents-10': '(DO NOT USE AFTER 10/2023) Minors/Adolescents (Ages 10-17)'},
            {'minors-adolescents-15': '(DO NOT USE AFTER 10/2023) Young Adult (Ages 15-17)'},
            {'families': 'Families'},
            {'couples': 'Couples'},
            {'groups': 'Groups'}
        ];
    }

    getGroupTherapySettings() {
        this.group_therapy_settings = [];
        const url = environment.apiBaseUrl + 'group-therapy-settings';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.group_therapy_settings = result.body[0].settings;
                console.log(this.group_therapy_settings);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading group therapy settings');
        });
    }

    getProvidersAtOfficeCode(office_code) {
        const params = new HttpParams().set('office_code', office_code);
        const url = environment.amdApiBaseUrl + 'get-all-providers';
        this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                const providers = result.body.sort(function(a, b) {
                    if ( a.name < b.name ) {
                        return -1;
                    }
                    if ( a.name > b.name ) {
                        return 1;
                    }
                    return 0;
                });
                this.providers[office_code] = providers;
                this.providers = this.providers;
                console.warn(this.providers);
                this.findGroupLeaders();
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading providers');
        });
    }

    getReferralVisitTooltip(referral) {
        if (referral && referral.referral_visit && referral.referral_visit.appointment_type && referral.referral_visit.column_heading && referral.referral_visit.start_datetime) {
            const start_datetime = moment(referral.referral_visit.start_datetime).format('llll');
            return `${referral.referral_visit.appointment_type} with ${referral.referral_visit.column_heading} on ${start_datetime}`;
        }
        return '';
    }

    getMessagesSentTooltip(referral) {
        if (referral && referral.messages_sent && referral.last_message_date_created) {
            const last_message_sent = moment(referral.last_message_date_created.toDate()).format('llll');
            return `Last SMS sent on  ${last_message_sent}`;
        }
        return '';
    }

    getResponseReceivedTooltip(referral) {
        if (referral && referral.response && referral.response_created) {
            const reponse_received = moment(referral.response_created.toDate()).format('llll');
            return `Last response received on  ${reponse_received}`;
        }
        return '';
    }

    getMessageHistoryTooltip(isDirty = false): string {
        if (isDirty) {
            return 'Changes have been made. You must save this referral before viewing message history';
        }
        return 'Click to view 2-way SMS history with this referral';
    }

    sendSingleSMS(referral, body = null) {
        const sendSingleSMS = this.cloudFunctions.httpsCallable('sendSingleSMS');
        sendSingleSMS({ referral: referral, body, password: 'Clarity9404!' }).subscribe(result => {
            console.log(result);
            this.globalService.saving = false;
        }, error => {
            console.log(error);
            this.globalService.saving = false;
        });
    }

}
