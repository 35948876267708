import { NgZone } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../environments/environment.prod';

export function AngularFirestoreAmd(platformId: Object, zone: NgZone) {
    return new AngularFirestore(environment.amdFirebase, 'firebase-project2', false, null, platformId, zone, null);
}

export function AngularFireAuthAmd(platformId: Object, zone: NgZone) {
    return new AngularFireAuth(environment.amdFirebase, 'firebase-project2-auth', platformId, zone);
}
