import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';

@Component({
    selector: 'app-link-provider-dialog',
    templateUrl: 'link-provider-dialog.html',
    styleUrls: ['link-provider-dialog.scss']
})
export class LinkProviderDialogComponent implements OnInit {

    project;
    projectFormGroup: FormGroup;

    responses;
    responses1;
    responses2;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        public globalService: GlobalService,
        private projectsService: ProjectsService,
        public dialogRef: MatDialogRef<LinkProviderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.project = data.project || null;
    }

    ngOnInit() {
        console.log(this.project);
        this.getProviderResponses();
        if (this.project) {
            this.projectFormGroup = this._formBuilder.group({
                providerResponsesId1Ctrl: [this.project.provider_responses_1_id],
                providerResponsesId2Ctrl: [this.project.provider_responses_2_id],
            });
        } else {
            this.toastsService.showSnackBar('No project data found, please try again', 'error');
            this.onNoClick();
        }
    }

    clear() {
        this.globalService.saving = true;
        const id = this.project.id;
        console.log(id);

        const updatedProject = {
            provider_responses_id: null,
            provider_responses_1_id: null,
            provider_responses_2_id: null,
        };

        const projectsRef = this.afs.firestore.doc('projects/' + id);
        projectsRef.set(updatedProject, {merge: true}).then(() => {
            this.globalService.saving = false;
            this.closeDialog(id);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    saveProject() {
        this.globalService.saving = true;
        const provider_responses_1_id = this.projectFormGroup.get('providerResponsesId1Ctrl').value;
        const provider_responses_2_id = this.projectFormGroup.get('providerResponsesId2Ctrl').value;
        const id = this.project.id;

        const updatedProject = {
            provider_responses_1_id: provider_responses_1_id,
            provider_responses_2_id: provider_responses_2_id,
        };

        const projectsRef = this.afs.firestore.doc('projects/' + id);
        projectsRef.set(updatedProject, {merge: true}).then(() => {
            this.globalService.saving = false;
            this.closeDialog(id);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    getProviderResponses() {
        const usersCollection1 = this.afs.collection<any>('provider-responses-1', ref => ref.orderBy('last_name'));
        usersCollection1.valueChanges({idField: 'id'}).subscribe((responses) => {
            this.responses1 = responses;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        const usersCollection2 = this.afs.collection<any>('provider-responses-2', ref => ref.orderBy('last_name'));
        usersCollection2.valueChanges({idField: 'id'}).subscribe((responses) => {
            this.responses2 = responses;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

}
