import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../../services/projects.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../../services/toasts.service';
import {GlobalService} from '../../../services/global.service';
import {AuthService} from '../../../auth/auth.service';
import {map, startWith} from 'rxjs/operators';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-new-google-user',
    templateUrl: 'new-google-user.html',
    styleUrls: ['new-google-user.scss']
})
export class NewGoogleUserComponent implements OnInit, OnDestroy {

    cell_phone: string;
    first_name: string;
    last_name: string;
    project_id: string;
    groups = [];

    importantGroupPrefixes = [
        'rn',
        'lv',
        'loop',
        'ah',
        'evanston',
        'oakbrook'
    ];

    userFormGroup: FormGroup;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        private http: HttpClient,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        public dialogRef: MatDialogRef<NewGoogleUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.cell_phone = data.cell_phone || null;
        this.first_name = data.first_name || null;
        this.last_name = data.last_name || null;
        this.project_id = data.project_id || null;
    }

    ngOnInit() {
        const email = (this.first_name.charAt(0) + this.last_name + '@claritychi.com').toLowerCase();
        this.userFormGroup = this._formBuilder.group({
            firstNameCtrl: [this.first_name, Validators.required],
            lastNameCtrl: [this.last_name, Validators.required],
            projectIdCtrl: [this.project_id, Validators.required],
            passwordCtrl: ['Clarity2021!', Validators.required],
            phoneCtrl: [this.cell_phone, Validators.required],
            emailCtrl: [email, Validators.required],
            groupsCtrl: [null, Validators.required],
        });

        const groupsCollection = this.afs.collection<any>('microsoft-groups', ref => ref.orderBy('name'));
        groupsCollection.valueChanges().subscribe((groups) => {
            const groups_list = groups.map(group => {
                if (!group['displayName']) {
                    group['displayName'] = '';
                }
                return group;
            });
            const final_groups = groups_list.filter(group => {
                for (const prefix of this.importantGroupPrefixes) {
                    if (group.email.startsWith(prefix)) {
                        return true;
                    }
                }
                return false;
            });
            this.groups = final_groups;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    saveUser() {
        console.log(this.userFormGroup.getRawValue());
        try {
            this.globalService.saving = true;
            const password = 'Clarity9404!';
            const user_email = this.userFormGroup.get('emailCtrl').value;
            const user_phone = this.userFormGroup.get('phoneCtrl').value;
            const user_password = 'Clarity2021!';
            const user_first_name = this.userFormGroup.get('firstNameCtrl').value;
            const user_last_name = this.userFormGroup.get('lastNameCtrl').value;
            const project_id = this.userFormGroup.get('projectIdCtrl').value;
            const groups = this.userFormGroup.get('groupsCtrl').value.join();

            const url = environment.cloudFunctionsBaseUrl + 'create-user';
            const params = new HttpParams()
                .set('password', password)
                .set('user_email', user_email)
                .set('user_phone', user_phone)
                .set('user_password', user_password)
                .set('user_first_name', user_first_name)
                .set('user_last_name', user_last_name)
                .set('project_id', project_id)
                .set('groups', groups);
            this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
                if (result.status === 200) {
                    console.log(result);
                    this.globalService.saving = false;
                    this.closeDialog(user_email);
                }
            }, error => {
                console.log(error);
                this.globalService.saving = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

    ngOnDestroy() {
    }

}
