import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { Message } from '../../../models/message.model';
import {MessagesService} from '../../../services/messages.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription, timer} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Component({
    selector: 'app-conversation',
    templateUrl: './conversation.component.html',
    styleUrls: ['./conversation.component.css']
})
export class ConversationComponent implements OnInit, OnDestroy {
    messages: Message[];
    cell_phone: string;
    first_name: string;
    last_name: string;
    isLoading = true;
    form: FormGroup;
    timerSubscription: Subscription;

    constructor(
        private messagesService: MessagesService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private scrollToService: ScrollToService,
    ) {
        this.cell_phone = data.cell_phone || null;
        this.first_name = data.first_name || null;
        this.last_name = data.last_name || null;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            phoneCtrl: [this.cell_phone, Validators.required],
            bodyCtrl: ['', Validators.required]
        });

        const reloadInterval = 20000;
        this.getMessages(true);
        this.timerSubscription = timer(reloadInterval, reloadInterval).subscribe( result => {
            this.getMessages();
        });
    }

    onSubmit() {
        const cell_phone = this.form.get('phoneCtrl').value;
        const body = this.form.get('bodyCtrl').value;
        this.messagesService.sendMessage(cell_phone, body).subscribe(result => {
                this.resetForm();
            },
            error => {
                this.resetForm();
            });
    }

    resetForm() {
        setTimeout(() => this.getMessages(true), 4000);
        this.form.reset();
        this.form.patchValue({
            phoneCtrl: this.cell_phone,
            bodyCtrl: '',
        });
    }

    getMessages(scrollToBottom: boolean = false) {
        this.messagesService.getConversation(this.cell_phone)
            .subscribe(messages => {
                this.messages = messages;
                this.isLoading = false;
                if (scrollToBottom) {
                    setTimeout(() => { this.scrollToBottom(); }, 100);
                }
            });
    }

    scrollToBottom(): void {
        const config: ScrollToConfigOptions = {
            target: 'bottom-button',
        };
        this.scrollToService.scrollTo(config);
    }

    scrollToTop(): void {
        const config: ScrollToConfigOptions = {
            target: 'top-button',
            offset: -100,
        };
        this.scrollToService.scrollTo(config);
    }

    ngOnDestroy() {
        this.timerSubscription.unsubscribe();
    }
}
