import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {AuthService} from '../../auth/auth.service';
import {GroupsService} from '../../services/groups.service';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import { saveAs } from 'file-saver';
import {environment} from '../../../environments/environment.prod';

@Component({
    selector: 'app-export-data-dialog',
    templateUrl: 'export-data-dialog.html',
    styleUrls: ['export-data-dialog.scss']
})
export class ExportDataDialogComponent implements OnInit {

    range: FormGroup;

    url;
    filename;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        private http: HttpClient,
        private groupsService: GroupsService,
        public dialogRef: MatDialogRef<ExportDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.url = this.data.url;
        this.filename = this.data.filename;
    }

    ngOnInit() {
        this.range = this._formBuilder.group({
            start: [null, Validators.required],
            end: [null, Validators.required]
        });
    }

    exportData() {
        this.globalService.saving = true;
        const start = moment(this.range.value.start).format('YYYY-MM-DD');
        const end = moment(this.range.value.end).format('YYYY-MM-DD');

        this.http
            .post(`${environment.apiBaseUrl}${this.url}`, {
                password: 'Clarity9404',
                start: start,
                end: end,
            }, {responseType: 'blob'}).subscribe(result => {
                this.globalService.saving = false;
                saveAs(result, this.filename);
                this.closeDialog();
            }, error => {
                console.error(error);
                this.globalService.saving = false;
            });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

}
