import {Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupsService} from '../../services/groups.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditProjectDialogComponent} from '../edit-project-dialog/edit-project-dialog';
import {GlobalService} from '../../services/global.service';
import * as moment from 'moment';
import {LinkProviderDialogComponent} from '../link-provider-dialog/link-provider-dialog';
import {ConversationComponent} from './conversation/conversation.component';
import {AuthService} from '../../auth/auth.service';
import {NewGoogleUserComponent} from './new-google-user/new-google-user';
import {ViewGoogleUserComponent} from './view-google-user/view-google-user';
import {QuickTaskDialogComponent} from './quick-task-dialog/quick-task-dialog';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit, OnDestroy {
    project: any;
    loading;
    lanes = [];
    membershipsLoaded = false;

    membershipsLoadedSubscription: Subscription;
    projectSubscription: Subscription;
    tasksSubscription: Subscription;
    viewAssigned = false;
    viewLateTasks = false;
    viewCompleted = true;
    viewSpecificGroup = false;
    projectTasks: any;
    providerResponses: any;
    providerResponses1: any;
    providerResponses2: any;
    openTaskId;

    constructor(private afs: AngularFirestore,
                public dialog: MatDialog,
                private toastsService: ToastsService,
                public globalService: GlobalService,
                private route: ActivatedRoute,
                public groupsService: GroupsService,
                public authService: AuthService,
                private router: Router) { }

    ngOnInit() {
        const projectId = this.route.snapshot.paramMap.get('id');
        const taskId = this.route.snapshot.paramMap.get('taskId');
        this.openTaskId = taskId;
        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
        });
        this.loadProject(projectId);
    }

    editProject() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            project: this.project
        };

        const dialogRef = this.dialog.open(EditProjectDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async projectId => {
            if (projectId) {
                console.log(projectId);
            }
        });
    }

    sendSms() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = '800px';
        dialogConfig.height = '90%';
        dialogConfig.data = {
            cell_phone: this.project.cell_phone,
            first_name: this.project.first_name,
            last_name: this.project.last_name,
        };

        const dialogRef = this.dialog.open(ConversationComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    createGoogleUser() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = '800px';
        // dialogConfig.height = '90%';
        dialogConfig.data = {
            cell_phone: this.project.cell_phone,
            first_name: this.project.first_name,
            last_name: this.project.last_name,
            project_id: this.project.id,
        };

        const dialogRef = this.dialog.open(NewGoogleUserComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    addQuickTask(lane) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            lane: lane,
            project: this.project,
        };

        const dialogRef = this.dialog.open(QuickTaskDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    linkProviderResponses() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            project: this.project
        };

        const dialogRef = this.dialog.open(LinkProviderDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async projectId => {
            if (projectId) {
                console.log(projectId);
            }
        });
    }

    taskIsLate(task) {
        const due_date = moment(task.due_date.toDate());
        const today = moment();
        return !task.completed && due_date.isBefore(today);
    }

    taskIsInSelectedGroup(task) {
        return this.groupsService.groups[task.assigned_to] && this.groupsService.groups[task.assigned_to]['selected'];
    }

    identifyTask(index, task) {
        return task.id + ' ' + (task?.comments?.length ?? 0);
    }

    getGroupName(name) {
        return name ?? 'Unknown';
    }

    loadProject(projectId) {
        this.loading = true;
        const projectsCollection = this.afs.collection<any>('projects').doc(projectId);
        this.projectSubscription = projectsCollection.valueChanges().subscribe((project) => {
            const tasksCollection = projectsCollection.collection('tasks', ref => ref.orderBy('due_date').orderBy('order'));
            this.tasksSubscription = tasksCollection.valueChanges({idField: 'id'}).subscribe((projectsTasks) => {
                project['tasks'] = projectsTasks;
                project['lanes'] = this.getLanes(projectsTasks);
                project['assigned_groups'] = this.groupsService.getGroupsAssignedToProject(project['tasks']);
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
            // this.loadProviderResponses(project);
            this.loadProviderResponses1(project);
            this.loadProviderResponses2(project);
            this.project = project;
            this.project['id'] = projectId;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    loadProviderResponses(project) {
        this.loading = true;
        if (project['provider_responses_id']) {
            const responsesCollection = this.afs.collection<any>('provider-responses').doc(project['provider_responses_id']);
            responsesCollection.valueChanges().subscribe((responses) => {
                this.providerResponses = responses;
                this.loading = false;
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
        } else {
            this.loading = false;
        }
    }

    loadProviderResponses1(project) {
        this.loading = true;
        if (project['provider_responses_1_id']) {
            const responsesCollection = this.afs.collection<any>('provider-responses-1').doc(project['provider_responses_1_id']);
            responsesCollection.valueChanges().subscribe((responses) => {
                this.providerResponses1 = responses;
                this.loading = false;
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
        } else {
            this.loading = false;
        }
    }

    loadProviderResponses2(project) {
        this.loading = true;
        if (project['provider_responses_2_id']) {
            const responsesCollection = this.afs.collection<any>('provider-responses-2').doc(project['provider_responses_2_id']);
            responsesCollection.valueChanges().subscribe((responses) => {
                this.providerResponses2 = responses;
                this.loading = false;
            }, error => {
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
        } else {
            this.loading = false;
        }
    }

    selectGroup(selected_group) {
        if (selected_group) {
            this.viewSpecificGroup = true;
            for (const [key, group] of Object.entries(this.groupsService.groups)) {
                if (key === selected_group) {
                    group['selected'] = true;
                } else {
                    group['selected'] = false;
                }
            }
        } else {
            this.viewSpecificGroup = false;
        }
    }

    getLanes(projectsTasks) {
        return [...Array.from(new Set(projectsTasks.map(x => x['lane'])))];
    }

    ngOnDestroy() {
        this.membershipsLoadedSubscription.unsubscribe();
        this.projectSubscription.unsubscribe();
        this.tasksSubscription.unsubscribe();
    }

}
