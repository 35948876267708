import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {MatStepper} from '@angular/material/stepper';
import {MessagesService} from '../../services/messages.service';
import {AuthService} from '../../auth/auth.service';
import {ProjectsService} from '../../services/projects.service';
import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-new-announcement-dialog',
    templateUrl: 'new-announcement-dialog.html',
    styleUrls: ['new-announcement-dialog.scss']
})
export class NewAnnouncementDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    groups = [];
    usersMap = {};
    selectedUsers = [];
    selectedUsersWithPhones = [];
    selectedUsersWithoutPhones = [];

    selectGroupsManually = false;
    selectedCode = null;
    selectedLocation = null;
    messageTypes = ['Both', 'SMS', 'Email'];
    messageType = 'Both';
    isTest = false;

    // TODO get codes, get templates per code + location
    codes = ['Silver - Active Shooter', 'Orange - Violent Patient', 'Red - Fire', 'Blue - Medical Emergency', 'Black - Weather Alert', 'Green - Emergency Operations Plan Activation'];
    groupLocationMap = {
        'All': ['allrivernorth@claritychi.com', 'allloop@claritychi.com', 'allarlingtonheights@claritychi.com', 'alllakeview@claritychi.com', 'allmokena@claritychi.com', 'alloakbrook@claritychi.com', 'allevanston@claritychi.com'],
        'Loop': ['allloop@claritychi.com'],
        'River North': ['allrivernorth@claritychi.com'],
        'Lakeview': ['alllakeview@claritychi.com'],
        'Mokena': ['allmokena@claritychi.com'],
        'Oakbrook': ['alloakbrook@claritychi.com'],
        'Arlington Heights': ['allarlingtonheights@claritychi.com'],
        'Evanston': ['allevanston@claritychi.com'],
        // 'All': ['EMSTEST@claritychi.com'],
        // 'Loop': ['EMSTEST@claritychi.com'],
        // 'River North': ['EMSTEST@claritychi.com'],
        // 'Lakeview': ['EMSTEST@claritychi.com'],
        // 'Mokena': ['EMSTEST@claritychi.com'],
        // 'Oakbrook': ['EMSTEST@claritychi.com'],
        // 'Arlington Heights': ['EMSTEST@claritychi.com'],
        // 'Evanston': ['EMSTEST@claritychi.com'],
    };

    codeLocationTemplateMap = {
        'Silver - Active Shooter': '[Clarity Clinic Alert] CODE SILVER ACTIVE: Active Shooter on premises. Follow lockdown procedures. Await further instructions. This is NOT a drill.\n\nhttps://link.claritychi.com/silver',
        'Orange - Violent Patient': '[Clarity Clinic Alert] CODE ORANGE ACTIVE: Aggressive Patient on site. Initiate safety protocols. Await further instructions. This is NOT a drill.\n\nhttps://link.claritychi.com/orange',
        'Red - Fire': '[Clarity Clinic Alert] CODE RED ACTIVE: Fire detected on premises. Evacuate via nearest exit. Await further instructions. This is NOT a drill.\n\nhttps://link.claritychi.com/red',
        'Blue - Medical Emergency': '[Clarity Clinic Alert] CODE BLUE ACTIVE: Medical Emergency at {{location}}. Immediate medical response required. This is NOT a drill.\n\nhttps://link.claritychi.com/blue',
        'Black - Weather Alert': '[Clarity Clinic Alert] CODE BLACK ACTIVE: Severe Weather Alert. Initiate weather emergency protocols. Seek shelter. Await further instructions. This is NOT a drill.\n\nhttps://link.claritychi.com/black',
        'Green - Emergency Operations Plan Activation': '[Clarity Clinic Alert] CODE GREEN ACTIVATED: All Clear. Emergency situation resolved. Resume normal operations. Await debriefing. This IS a confirmed all clear.\n\nhttps://link.claritychi.com/green',
    };

    groupsDataSource = new MatTableDataSource(this.groups);
    @ViewChild('groupsPaginator') groupsPaginator: MatPaginator;
    @ViewChild('groupsSort', {read: MatSort, static: true}) groupsSort: MatSort;
    groupsSelection = new SelectionModel<any>(true, []);

    groupsColumns: string[] = [
        'select',
        'name',
        'description',
        'email',
        'size',
    ];

    paycomUsersMap = {};

    @ViewChild('stepper') stepperRef: MatStepper;

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        private messagesService: MessagesService,
        private toastsService: ToastsService,
        public globalService: GlobalService,
        public projectsService: ProjectsService,
        private http: HttpClient,
        public dialogRef: MatDialogRef<NewAnnouncementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
    }

    ngOnInit() {
        const groupsCollection = this.afs.collection<any>('microsoft-groups', ref => ref.orderBy('name'));

        groupsCollection.valueChanges().subscribe((groups) => {
            console.log(groups);
            const groups_list = groups.map(group => {
                if (!group['displayName']) {
                    group['displayName'] = '';
                }
                return group;
            });
            this.groups = groups_list;
            this.groupsDataSource.data = this.groups;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        const usersCollection = this.afs.collection<any>('microsoft-users');
        usersCollection.valueChanges().subscribe((users) => {
            console.log(users);
            const usersMap = {};
            for (const user of users) {
                usersMap[user.email] = user;
            }
            this.usersMap = usersMap;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });

        this.firstFormGroup = this._formBuilder.group({
            groups: [null, Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            message: [null, Validators.required]
        });

        this.groupsDataSource.filterPredicate = function(data, filter: string): boolean {
            if (data['name'] != null && data['description'] != null && data['email'] != null) {
                return data['name'].toLowerCase().includes(filter)
                    || data['description'].toLowerCase().includes(filter)
                    || data['email'].toLowerCase().includes(filter);
            } else {
                return false;
            }
        };
        this.loadPaycomData();
    }

    printValues() {
        console.log(this.messageType, this.secondFormGroup.invalid, this.globalService.saving);
    }

    isReadOnly() {
        return this.selectedCode && !this.authService.admin;
    }

    loadPaycomData() {
        try {
            this.globalService.saving = true;
            const url = environment.apiBaseUrl + 'paycom-users/phones';
            const params = new HttpParams()
                .set('password', 'Clarity9404');
            this.http.get(url, {observe : 'response'}).subscribe(result => {
                if (result.status === 200) {
                    console.log(result.body);
                    this.paycomUsersMap = result.body;
                    // TODO create lookup object for phone numbers
                    this.globalService.saving = false;
                }
            }, error => {
                console.log(error);
                this.globalService.saving = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    getGroupFromLocation(location) {
        const defaultGroupForAll = ['allrivernorth@claritychi.com', 'allloop@claritychi.com', 'allarlingtonheights@claritychi.com', 'alllakeview@claritychi.com', 'allmokena@claritychi.com', 'alloakbrook@claritychi.com', 'allevanston@claritychi.com'];
        try {
            return this.groupLocationMap[location] ?? defaultGroupForAll;
        } catch (error) {
            this.toastsService.showSnackBar(error, 'error', 5000);
            return;
        }
    }

    getTemplateFromCode(code) {
        try {
            const codeLowercase = code.split(' - ')[0].toLowerCase();
            return `code-${codeLowercase}`;
        } catch (error) {
            this.toastsService.showSnackBar(error, 'error', 5000);
            return;
        }
    }

    getMessageFromCodeAndLocation(code, location) {
        try {
            let message = this.codeLocationTemplateMap[code];
            message = message.replace('{{location}}', location);
            if (this.isTest) {
                message = '[THIS IS A TEST] ' + message;
            }
            return message;
        } catch (error) {
            this.toastsService.showSnackBar(error, 'error', 5000);
            return;
        }
    }

    selectGroup() {
        if (this.selectGroupsManually) {
            this.selectedCode = null;
            this.selectedLocation = null;
            this.firstFormGroup.patchValue( {
                groups: this.groupsSelection.selected
            });
            this.secondFormGroup.patchValue( {
                message: ''
            });
        } else {
            // TODO need to get all groups that match the selected code and location
            const group = this.getGroupFromLocation(this.selectedLocation);
            if (group) {
                this.firstFormGroup.patchValue({
                    groups: group
                });
            }
            // set template
            this.secondFormGroup.patchValue( {
                message: this.getMessageFromCodeAndLocation(this.selectedCode, this.selectedLocation)
            });
        }

        this.selectedUsers = this.getUsersFromGroups(this.firstFormGroup.value.groups);
        console.log(this.selectedUsers);

        // lookup phone, trust paycom first, fallback to microsoft
        const paycomUsers = this.selectedUsers.map((user) => {
            return {...user, phone: this.paycomUsersMap[user['email']] ? this.paycomUsersMap[user['email']] : user['phone']};
        });
        this.selectedUsersWithPhones = paycomUsers.filter(user => user['phone'] && user['phone'] !== null);
        this.selectedUsersWithoutPhones = paycomUsers.filter(user => !user['phone'] || user['phone'] === null);

        console.log(this.selectedUsersWithPhones, this.selectedUsersWithoutPhones);

        // TODO paycom lookups fail for incorrect emails, such as sgreen vs lgreen, or drslott vs drlott
        // have to make sure Paycom / Microsoft / AMD all have same email

        this.stepperRef.next();
    }

    selectMessage() {
        this.stepperRef.next();
    }

    selectCode(code: string) {
        this.selectedCode = code;
    }

    selectLocation(location: string) {
        this.selectedLocation = location;
    }

    selectSimple(simple) {
        this.selectGroupsManually = simple;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    getGroupObjectsFromGroups(groups) {
        const groupObjects = this.groups.filter(group => groups.includes(group.email));
        return groupObjects;
    }

    getUsersFromGroups(groups) {
        // Gets a list of user objects who are members of the combined list of groups who are not archived or suspended
        const groupObjects = this.groups.filter(group => groups.includes(group.email));
        let allMemberEmails = [];
        for (const group of groupObjects) {
            allMemberEmails = allMemberEmails.concat(group.members);
        }
        allMemberEmails = allMemberEmails.filter(this.onlyUnique);

        // allMembers = allMembers.filter(user => this.usersMap[user] && this.usersMap[user]['phone']);
        allMemberEmails = allMemberEmails.filter(user => this.usersMap[user] && this.usersMap[user]['archived'] !== true && this.usersMap[user]['suspended'] !== true);

        const allUsers = [];
        for (const member of allMemberEmails) {
            allUsers.push(this.usersMap[member]);
        }

        return allUsers;
    }

    sendEmails(emails, emailTemplate) {
        // TODO split emails into separate individual emails so no one sees entire TO list?
        this.globalService.saving = true;
        const batch = this.afs.firestore.batch();

        const newEmailRef = this.afs.firestore.collection('mail').doc();
        const email_data = {
            to: emails,
            from: 'Clarity Clinic <emergency@claritychi.com>',
            replyTo: 'Clarity Clinic <emergency@claritychi.com>',
            template: {
                name: emailTemplate,
                data: {
                    link: 'https://claritychi.com',
                    is_test: this.isTest ? '[THIS IS A TEST] ' : '',
                }
            }
        };
        batch.set(newEmailRef, email_data);
        batch.commit().then(() => {
            this.globalService.saving = false;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    sendAnnouncement() {
        this.globalService.saving = true;
        const message = this.secondFormGroup.value.message;

        if (this.messageType === 'SMS' || this.messageType === 'Both') {
            const phoneNumbers = this.selectedUsersWithPhones.map(user => user['phone']);
            // phoneNumbers.push('15178627425');
            console.log(phoneNumbers, message);

            this.messagesService.sendAnnouncement(phoneNumbers.join(), message)
                .subscribe(result => {
                    this.saveAnnouncementToFirestore(this.groupsSelection.selected, phoneNumbers, message);
                }, error => {
                    console.log(error);
                    this.globalService.saving = false;
                    this.toastsService.showSnackBar('Error sending announcement', 'error', 5000);
                });
        }

        if (this.messageType === 'Email' || this.messageType === 'Both') {
            const emails = this.selectedUsers.map(user => user['email']);
            // emails.push('teftekhar@claritychi.com');
            console.log(emails);
            const emailTemplate = this.getTemplateFromCode(this.selectedCode);
            this.sendEmails(emails, emailTemplate);
        }
    }

    saveAnnouncementToFirestore(groups, phone_numbers, message) {
        const created_by = this.authService.user.displayName;

        const announcement = {
            groups: groups,
            phone_numbers: phone_numbers,
            message: message,
            created: new Date(),
            created_by: created_by
        };

        const announcementRef = this.afs.firestore.collection('announcements').doc();
        announcementRef.set(announcement, {merge: true}).then(() => {
            this.toastsService.showSnackBar('Alert sent successfully', 'info', 5000);
            this.globalService.saving = false;
            this.closeDialog(announcement);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    applyFilter(filterValue: string) {
        this.groupsDataSource.filter = filterValue.trim().toLowerCase();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

    ngAfterViewInit() {
        this.groupsDataSource.sort = this.groupsSort;
        this.groupsDataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId].toLocaleLowerCase();
        this.groupsDataSource.paginator = this.groupsPaginator;
    }

    ngOnDestroy() {
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllCustomSelected() {
        const numSelected = this.groupsSelection.selected.length;
        const numRows = this.groupsDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterCustomToggle() {
        this.isAllCustomSelected() ?
            this.groupsSelection.clear() :
            this.groupsDataSource.data.forEach(group => this.groupsSelection.select(group.email));
    }

    /** The label for the checkbox on the passed row */
    customCheckboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllCustomSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.groupsSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

}
