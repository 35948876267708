import {GlobalService} from '../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../services/toasts.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NewAnnouncementDialogComponent} from './new-announcement-dialog/new-announcement-dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {GroupsService} from '../services/groups.service';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, AfterViewInit, OnDestroy {

    loading;
    membershipsLoadedSubscription: Subscription;
    membershipsLoaded = false;
    announcementsDataSource = new MatTableDataSource(null);
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    announcementsColumns: string[] = [
        'message',
        'groups',
        'phone_numbers',
        'created_by',
        'created'
    ];

    constructor(public globalService: GlobalService,
                public dialog: MatDialog,
                private router: Router,
                private groupsService: GroupsService,
                private http: HttpClient,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
            if (this.membershipsLoaded) {
                this.handleRouteAuthentication();
            }
        });
        this.loadAnnouncements();
        this.announcementsDataSource.filterPredicate = function(data, filter: string): boolean {
            if (data['message'] != null) {
                return data['message'].toLowerCase().includes(filter);
            } else {
                return false;
            }
        };
    }

    handleRouteAuthentication() {
        // admins and PMs only

        if (this.authService.admin) {
            return;
        }

        const allowedGroups = [
            'practicemanagermaster@claritychi.com',
        ];

        for (const group of this.groupsService.currentUsersGroups) {
            if (allowedGroups.includes(group)) {
                return;
            }
        }

        this.router.navigate(['help']);
    }

    newAnnouncement() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {};

        const dialogRef = this.dialog.open(NewAnnouncementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async announcementId => {
            if (announcementId) {
                console.log(announcementId);
            }
        });
    }

    loadAnnouncements() {
        this.loading = true;
        this.announcementsDataSource.data = [];
        const announcementsCollection = this.afs.collection<any>('announcements', ref => ref.orderBy('created', 'desc'));
        announcementsCollection.valueChanges({idField: 'id'}).subscribe((announcements) => {
            console.log(announcements);
            this.announcementsDataSource.data = announcements;
            this.announcementsDataSource.sort = this.sort;
            this.loading = false;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    applyFilter(filterValue: string) {
        this.announcementsDataSource.filter = filterValue.trim().toLowerCase();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.membershipsLoadedSubscription.unsubscribe();
    }

}
