import {GlobalService} from '../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../services/toasts.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {BoardService} from '../services/kanban.service';
import {Subscription} from 'rxjs';
import {NewKanbanTaskDialogComponent} from './new-kanban-task-dialog/new-kanban-task-dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {GroupsService} from '../services/groups.service';
import {AuthService} from '../auth/auth.service';

@Component({
    selector: 'app-kanban',
    templateUrl: './kanban.component.html',
    styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit, AfterViewInit, OnDestroy {

    loading = true;

    sub: Subscription;
    membershipsLoadedSubscription: Subscription;

    boards;
    membershipsLoaded = false;

    kanbanType;

    constructor(public globalService: GlobalService,
                public dialog: MatDialog,
                private router: Router,
                private http: HttpClient,
                private route: ActivatedRoute,
                private groupsService: GroupsService,
                private authService: AuthService,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                private boardService: BoardService) {
    }

    // TODO need to design new data schema
    // final drag/drop requires separate lists so we can move between lists
    // maybe each task is a document, with lane as a key, and then create lanes on demand?
    // need to ensure every time we drag/drop it updates lives for others

    drop(event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data.tasks, event.previousIndex, event.currentIndex);
            this.boardService.updateTasks(this.kanbanType, event.container.data.id, event.container.data.tasks);
        } else {
            transferArrayItem(event.previousContainer.data.tasks,
                event.container.data.tasks,
                event.previousIndex,
                event.currentIndex);
            this.boardService.updateTasks(this.kanbanType, event.previousContainer.data.id, event.previousContainer.data.tasks);
            this.boardService.updateTasks(this.kanbanType, event.container.data.id, event.container.data.tasks);
        }
    }

    taskSaved(event, boardId, tasks) {
        console.log('SAVED!');
        try {
            if (event) {
                if (event.isNew) {
                    console.warn('IS NEW!');
                    this.boardService.updateTasks(this.kanbanType, boardId, [
                        ...tasks,
                        event.task
                    ]);
                } else {
                    console.warn('IS EXISTING!');
                    const update = tasks;
                    update.splice(event.index, 1, event.task);
                    this.boardService.updateTasks(this.kanbanType, boardId, tasks);
                }
            }
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    ngOnInit() {
        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
            if (this.membershipsLoaded && this.kanbanType) {
                this.handleRouteAuthentication();
            }
        });
        this.route.params.subscribe(params => {
            this.kanbanType = this.route.snapshot.paramMap.get('type');
            if (!this.kanbanType) {
                this.kanbanType = 'therapy';
            }
            if (this.membershipsLoaded && this.kanbanType) {
                this.handleRouteAuthentication();
            }
            this.loading = true;
            this.sub = this.boardService
                .getUserBoards(this.kanbanType)
                .subscribe(boards => (this.boards = boards));
        });
    }



    handleRouteAuthentication() {
        let allowedUsers = [
            'tjavidan@claritychi.com',
            'jperry@claritychi.com',
            'dgratzke@claritychi.com',
            'kabbene@claritychi.com',
            'drkulik@claritychi.com',
            'teftekhar@claritychi.com',
            'dlopez@claritychi.com',
            'shasan@claritychi.com',
            'drnoreika@claritychi.com',
            'cnoreika@claritychi.com',
        ];

        console.log(this.kanbanType);

        if (this.kanbanType === 'billing') {
            allowedUsers = [
                'sklus@claritychi.com',
                'jpickham@claritychi.com',
                'igomez@claritychi.com',
                'trivera@claritychi.com',
                'lalvarado@claritychi.com',
                'tjavidan@claritychi.com',
                'iglasper@claritychi.com',
                'dlopez@claritychi.com',
                'aocasio@claritychi.com',
            ];
        } else if (this.kanbanType === 'operations') {
            allowedUsers = [
                'rmaskey@claritychi.com',
                'tjavidan@claritychi.com',
                'dlopez@claritychi.com',
            ];
        } else if (this.kanbanType === 'apps') {
            allowedUsers = [
                'teftekhar@claritychi.com',
                'mbecker@claritychi.com',
                'aknouse@claritychi.com',
                'jmarinier@claritychi.com',
                'abobak@claritychi.com',
                'hholmes@claritychi.com',
            ];
        }

        if (allowedUsers.includes(this.authService.user.email)) {
            return;
        }

        let allowedGroups = [
            'therapydirectormaster@claritychi.com',
            'hrmaster@claritychi.com',
            'practicemanagermaster@claritychi.com',
            'clinicalsupervisors@claritychi.com',
            'alltherapysupervisors@claritychi.com',
            'alltherapydirectors@claritychi.com',
        ];

        if (this.kanbanType === 'billing') {
            allowedGroups = [
                'allhr@claritychi.com',
                'billingmaster@claritychi.com',
            ];
        } else if (this.kanbanType === 'therapy') {
            allowedGroups = [
                'allhr@claritychi.com',
                'therapydirectormaster@claritychi.com',
                'clinicalsupervisors@claritychi.com',
                'alltherapysupervisors@claritychi.com',
                'alltherapydirectors@claritychi.com',
            ];
        } else if (this.kanbanType === 'operations') {
            allowedGroups = [
                'allhr@claritychi.com',
                'practicemanagermaster@claritychi.com',
            ];
        } else if (this.kanbanType === 'apps') {
            allowedGroups = [
                'allhr@claritychi.com',
            ];
        }

        for (const group of this.groupsService.currentUsersGroups) {
            if (allowedGroups.includes(group)) {
                return;
            }
        }

        this.router.navigate(['help']);
    }

    ngAfterViewInit() {
    }

    newKanbanTask() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            type: this.kanbanType
        };

        const dialogRef = this.dialog.open(NewKanbanTaskDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                // console.log(result);
                await this.boardService.insertTask(this.kanbanType, result.board_id, result.task);
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.membershipsLoadedSubscription.unsubscribe();
    }

}
