import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { switchMap, map } from 'rxjs/operators';
import { Board, KanbanTask } from '../models/board.model';

@Injectable({
    providedIn: 'root'
})
export class BoardService {
    constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {}

    types = {
        'therapy': 'boards',
        'psychiatry': 'boards-psychiatry',
        'operations': 'boards-operations',
        'billing': 'boards-billing',
        'apps': 'boards-apps',
    };
    /**
     * Get all boards owned by current user
     */
    getUserBoards(type) {
        return this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.db
                        .collection<Board>(this.types[type], ref =>
                            ref.where('priority', '<', 6).orderBy('priority')
                        )
                        .valueChanges({ idField: 'id' });
                } else {
                    return [];
                }
            }),
            // map(boards => boards.sort((a, b) => a.priority - b.priority))
        );
    }

    updateTasks(type: string, boardId: string, tasks: KanbanTask[]) {
        return this.db
            .collection(this.types[type])
            .doc(boardId)
            .update({ tasks });
    }

    insertTask(type: string, boardId: string, task: KanbanTask) {
        return this.db
            .collection(this.types[type])
            .doc(boardId)
            .update({ tasks: firebase.firestore.FieldValue.arrayUnion(task) });
    }

    removeTask(type: string, boardId: string, task: KanbanTask) {
        console.log(type, boardId, task);
        return this.db
            .collection(this.types[type])
            .doc(boardId)
            .update({
                tasks: firebase.firestore.FieldValue.arrayRemove(task)
            });
    }
}
