import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {timer} from 'rxjs';

@Component({
    selector: 'app-login-status',
    templateUrl: './login-status.component.html',
    styleUrls: ['./login-status.component.scss']
})
export class LoginStatusComponent implements OnInit {

    statusColor = '';

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        const reloadInterval = 10000;

        timer(1000, reloadInterval).subscribe(async result => {
            const needsRefresh = await this.authService.checkIfTokenNeedsRefresh();
            if (needsRefresh) {
                this.statusColor = 'warn';
            } else {
                this.statusColor = 'accent';
            }
        });
    }

}
