export class Message {
  public from: string;
  public to: string;
  public body: string;
  public sid: string;
  public isInbound: boolean;
  public isOutbound: boolean;
  public dateSent: string;
  public name: string;
  public unread: boolean;
  public step: number;
  public hidden: boolean;
  public errorCode: number;
  public errorMessage: string;
  public status: string;

  constructor({body, from, to, sid, direction, isInbound, isOutbound, dateSent, errorCode, errorMessage, status, name='', unread=false, step=-1, hidden=false}) {
    this.body = body;
    this.from = from;
    this.to = to;
    this.sid = sid;
    this.isInbound = isInbound;
    this.isOutbound = isOutbound;
    this.dateSent = dateSent;
    this.name = name;
    this.unread = unread;
    this.step = step;
    this.hidden = hidden;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.status = status;
  }

  direction() {
    return this.isInbound ? 'inbound' : 'outbound';
  }
}
