import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from 'firebase';
import * as moment from 'moment';
import {GroupsService} from '../services/groups.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    admin;
    outreachDirector;
    user: User;
    _userId;

    constructor(public afAuth: AngularFireAuth, public router: Router, private groupsService: GroupsService) {
        this.afAuth.authState.subscribe(async user => {
            if (user) {
                this.user = user;
                this._userId = user.uid;
                console.log(this.user.email);
                this.groupsService.initGroupsService(user.email, user.uid);
                // console.log('Logged in user id:', this._userId);
                localStorage.setItem('user', JSON.stringify(this.user));
                const currentUser = await this.afAuth.currentUser;
                if (currentUser) {
                    console.log(currentUser.email);

                    // console.log(JSON.parse(JSON.stringify(currentUser)));
                    if (!currentUser.emailVerified) {
                        console.warn('User has not confirmed email yet... Please confirm email.');
                    }
                    // set local token immediately, will refresh later if needed
                    const tokenResult = JSON.parse(JSON.stringify(currentUser)).stsTokenManager.accessToken;
                    localStorage.setItem('microsoftAuthToken', tokenResult);

                    currentUser.getIdTokenResult(true)
                        .then((idTokenResult) => {
                            console.log('Logged in token:', idTokenResult);
                            if (idTokenResult.claims.admin) {
                                this.admin = true;
                            } else {
                                this.admin = false;
                            }
                            if (idTokenResult.claims.outreachDirector) {
                                this.outreachDirector = true;
                            } else {
                                this.outreachDirector = false;
                            }
                            localStorage.setItem('microsoftAuthToken', idTokenResult.token);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        });
    }

    async logout() {
        await this.afAuth.signOut();
        this.clearLocalStorage();
        await this.router.navigate(['login']);
    }

    clearLocalStorage() {
        localStorage.removeItem('user');
        localStorage.removeItem('microsoftAuthToken');
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return user !== null && this._userId;
    }

    get userId(): string {
        return this._userId;
    }

    getToken() {
        return localStorage.getItem('microsoftAuthToken');
    }

    async checkIfTokenNeedsRefresh() {
        const currentUser = await this.afAuth.currentUser;
        if (currentUser) {
            currentUser.getIdTokenResult(true)
                .then((idTokenResult) => {
                    localStorage.setItem('microsoftAuthToken', idTokenResult.token);
                    return false;
                }).catch((error) => {
                    console.log(error);
                    return true;
                });
        } else {
            return true;
        }
    }

}
