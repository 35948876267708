import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: 'snackbar.component.html'
})
export class SnackBarComponent {
    status = 'info';

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.status = data.status;
    }
}
