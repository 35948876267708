import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult} from 'firebaseui-angular';
import {Subscription} from 'rxjs';
import {GlobalService} from './services/global.service';
import {GroupsService} from './services/groups.service';
import {SanityService} from './services/sanity.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    savingSubscription: Subscription;
    saveInProgress = false;

    constructor(public authService: AuthService, public groupsService: GroupsService, private globalService: GlobalService, private sanityService: SanityService) {

    }

    ngOnInit(): void {
        this.savingSubscription = this.globalService.saving$.subscribe(saving => {
            this.saveInProgress = saving;
            if (this.saveInProgress) {
                document.getElementById('lock-overlay').style.display = 'block';
            } else {
                document.getElementById('lock-overlay').style.display = 'none';
            }
        });
        //
        // this.sanityService.getData(`*[_type == "provider"]`).then((providers) => {
        //     console.warn(providers);
        // });
    }

    isPm() {
        const allowedGroups = [
            'practicemanagermaster@claritychi.com',
        ];

        for (const group of this.groupsService.currentUsersGroups) {
            if (allowedGroups.includes(group)) {
                return true;
            }
        }

        return false;
    }

    ngOnDestroy() {
        this.savingSubscription.unsubscribe();
    }

}
