import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { Message } from '../models/message.model';
import { throwError } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    constructor(
        private http: Http,
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {}

    getConversation(phoneNumber: string) {
        return this.http
            .get(`${environment.apiBaseUrl}messages/${phoneNumber}`)
            .pipe(map(res => res.json()))
            .pipe(map(obj => obj.messages.map(obj2 => new Message(obj2))));
    }

    sendMessage(phoneNumber: string, body: string) {
        return this.http
            .post(`${environment.apiBaseUrl}messages`, {
                password: 'Clarity9404',
                phoneNumber: phoneNumber,
                body: body,
            })
            .pipe(map(res => res.json()));
    }

    sendAnnouncement(phoneNumbers: string, message: string) {
        // return this.http
        //     .post(`${environment.apiBaseUrl}messages`, {
        //         password: 'Clarity9404',
        //         phoneNumber: phoneNumbers,
        //         body: message,
        //     })
        //     .pipe(map(res => res.json()));
        return this.http
            .post(`${environment.apiBaseUrl}announcements`, {
                password: 'Clarity9404',
                phoneNumber: phoneNumbers,
                body: message,
            })
            .pipe(map(res => res.json()));
    }
}
