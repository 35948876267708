import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../../services/toasts.service';
import {GroupsService} from '../../../services/groups.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../../auth/auth.service';
import * as moment from 'moment';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {toHTML} from '@portabletext/to-html';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit, AfterViewInit {

    @Input() task;
    @Input() projectId;
    @Input() openOnInit;

    taskFormGroup: FormGroup;

    toggled = false;
    mouseOvered = false;
    taskOpen = false;
    nearDueDate = false;
    afterDueDate = false;

    constructor(private afs: AngularFirestore,
                private authService: AuthService,
                private toastsService: ToastsService,
                public groupsService: GroupsService,
                private _formBuilder: FormBuilder,
                private scrollToService: ScrollToService) { }

    ngOnInit() {
        console.log('Loaded task.');
        this.calculateDueDateAlerts();
        this.resetForm();
        this.formatComments();
        this.loadDescriptionBlockContent();
    }

    loadDescriptionBlockContent() {
        // TODO should we always prioritize and overwrite legacy description field if block content available?
        if (this.task.descriptionBlockContent) {
            this.task.description = toHTML(this.task.descriptionBlockContent);
        }
    }

    ngAfterViewInit() {
        if (this.openOnInit) {
            setTimeout(() => {
                this.onClickOpen();
                this.triggerScrollTo();
            }, 1000);
        }
    }

    triggerScrollTo() {
        const config: ScrollToConfigOptions = {
            target: this.task.id,
            offset: -100,
        };
        this.scrollToService.scrollTo(config);
    }

    formatComments() {
        if (this.task.comments && this.task.comments.length) {
            const formatted_comments = [];
            for (const comment of this.task.comments) {
                const formatted_comment = {...comment};
                let formatted_text = formatted_comment.text;
                formatted_text = formatted_text.replace(/(@\w+)/g, '<span class="mentioned">$1</span>');
                formatted_comment.text = formatted_text;
                formatted_comments.push(formatted_comment);
            }
            this.task.formatted_comments = formatted_comments;
        }
    }

    formatMention(text) {
        return '@' + text.username + ' ';
    }

    calculateDueDateAlerts() {
        if (!this.task.completed) {
            const today = moment();
            const due = moment(this.task.due_date.toDate());
            const daysUntilDue = due.diff(today, 'days');
            if (daysUntilDue < 0) {
                this.afterDueDate = true;
            } else if (daysUntilDue < 7) {
                this.nearDueDate = true;
            }
        }
    }

    onClickOpen() {
        this.taskOpen = true;
        this.resetForm();
    }

    onClickOutside() {
        this.taskOpen = false;
    }

    resetForm() {
        this.taskFormGroup = this._formBuilder.group({
            assignedToCtrl: [this.task.assigned_to],
            completedCtrl: [this.task.completed],
            newCommentCtrl: ['']
        });
    }

    assignTo(group_email) {
        console.log(group_email);
        this.taskFormGroup.patchValue({
            assignedToCtrl: group_email
        });
        this.save();
    }

    async save() {
        this.taskOpen = false;
        const task = {...this.task};
        task.completed = this.taskFormGroup.get('completedCtrl').value;
        task.assigned_to = this.taskFormGroup.get('assignedToCtrl').value;

        const new_comment_text = this.taskFormGroup.get('newCommentCtrl').value;
        if (new_comment_text) {
            const new_comment = {
                text: new_comment_text,
                author: this.authService.user.displayName,
                author_id: this.authService.user.uid,
                date: new Date()
            };
            if (!task.comments) {
                task.comments = [];
            }
            task.comments = [...task.comments];
            task.comments.push(new_comment);
        }

        if (!this.task.completed && task.completed) {
            task.completed_by = this.authService.user.displayName || 'Unknown User';
            task.completed_at = new Date();
        } else if (!task.completed) {
            task.completed_by = '';
            task.completed_at = '';
        }

        try {
            const itemDoc = this.afs.doc<any>('projects/' + this.projectId).collection('tasks').doc(this.task.id);
            itemDoc.update(task);
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    getFirstNameLastInitial(name: string) {
        if (name.length) {
            let formatted_name = '';
            const split_name = name.split(' ');
            formatted_name += split_name[0];
            if (split_name?.[1]) {
                formatted_name += ' ' + split_name[1][0] + '.';
            }
            return formatted_name;
        }
        return null;
    }

}
