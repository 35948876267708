import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {GlobalService} from '../../services/global.service';
import {BoardService} from '../../services/kanban.service';
import {GroupsService} from '../../services/groups.service';

@Component({
    selector: 'app-employee-task',
    templateUrl: './employee-task.component.html',
    styleUrls: ['./employee-task.component.scss']
})
export class EmployeeTaskComponent implements OnInit {

    @Input() task;
    @Input() boardId;
    @Input() index;
    @Input() type;

    @Output() saveTask = new EventEmitter<any>();

    taskFormGroup: FormGroup;

    toggled = false;
    mouseOvered = false;
    taskOpen = false;
    nearDueDate = false;
    afterDueDate = false;
    showComments = false;

    constructor(private afs: AngularFirestore,
                private authService: AuthService,
                private globalService: GlobalService,
                private toastsService: ToastsService,
                public groupsService: GroupsService,
                private boardService: BoardService,
                private _formBuilder: FormBuilder) { }

    ngOnInit() {
        // console.log('Loaded task.');
        this.resetForm();
        this.formatComments();
    }

    resetForm() {
        this.taskFormGroup = this._formBuilder.group({
            newCommentCtrl: ['']
        });
    }

    formatComments() {
        if (this.task.comments && this.task.comments.length) {
            const formatted_comments = [];
            for (const comment of this.task.comments) {
                const formatted_comment = {...comment};
                let formatted_text = formatted_comment.text;
                formatted_text = formatted_text.replace(/(@\w+)/g, '<span class="mentioned">$1</span>');
                formatted_comment.text = formatted_text;
                formatted_comments.push(formatted_comment);
            }
            // console.log(formatted_comments);
            this.task.formatted_comments = formatted_comments;
        }
    }

    formatMention(text) {
        return '@' + text.username + ' ';
    }

    onClickOpen() {
        this.taskOpen = true;
    }

    onClickOutside() {
        this.taskOpen = false;
    }

    async delete() {
        this.taskOpen = false;
        console.log(this.type);
        await this.boardService.removeTask(this.type, this.boardId, this.task);
    }

    async keyUpEnter() {
        console.log('saving');
        await this.save();
    }

    async save() {
        this.taskOpen = false;
        const task = {...this.task};

        const new_comment_text = this.taskFormGroup.get('newCommentCtrl').value;
        if (new_comment_text) {
            const new_comment = {
                text: new_comment_text,
                author: this.authService.user.displayName,
                author_id: this.authService.user.uid,
                date: new Date(),
                isNew: true
            };
            if (!task.comments) {
                task.comments = [];
            }
            task.comments = [...task.comments];
            task.comments.push(new_comment);
        }

        this.saveTask.emit({task: task, index: this.index});
    }

    typeOf(value) {
        return typeof value;
    }

    getFirstNameLastInitial(name: string) {
        if (name.length) {
            let formatted_name = '';
            const split_name = name.split(' ');
            formatted_name += split_name[0];
            if (split_name?.[1]) {
                formatted_name += ' ' + split_name[1][0] + '.';
            }
            return formatted_name;
        }
        return null;
    }

}
