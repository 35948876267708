import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../../services/projects.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../../services/toasts.service';
import {GlobalService} from '../../../services/global.service';
import {AuthService} from '../../../auth/auth.service';
import {map, startWith} from 'rxjs/operators';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-view-google-user',
    templateUrl: 'view-google-user.html',
    styleUrls: ['view-google-user.scss']
})
export class ViewGoogleUserComponent implements OnInit, OnDestroy {

    email;
    user;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        private http: HttpClient,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        public dialogRef: MatDialogRef<ViewGoogleUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.email = data.email;
    }

    ngOnInit() {
        const usersCollection = this.afs.collection<any>('google-workspace-users', ref => ref.where('email', '==', this.email));
        usersCollection.valueChanges().subscribe((users) => {
            console.log(users);
            if (users.length) {
                this.user = users[0];
            }
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

    ngOnDestroy() {
    }

}
