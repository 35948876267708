import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {GlobalService} from '../../services/global.service';
import {AuthService} from '../../auth/auth.service';
import {GroupsService} from '../../services/groups.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AutoCompleteValidator} from '../../autocomplete-validator';

@Component({
    selector: 'app-assign-dialog',
    templateUrl: 'assign-dialog.html',
    styleUrls: ['assign-dialog.scss']
})
export class AssignDialogComponent implements OnInit {

    form: FormGroup;
    form_label = 'Assign Referral to User';
    filteredUsers: Observable<any[]>;
    assigned_to;

    constructor(
        private authService: AuthService,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        public groupsService: GroupsService,
        public dialogRef: MatDialogRef<AssignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.assigned_to = this.data.assigned_to;
    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            assigned_to: [this.assigned_to ?? '', [Validators.required, AutoCompleteValidator.mustBeInSimpleList(this.groupsService.allUsers)]],
        });

        this.filteredUsers = this.form.get('assigned_to').valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterUsers(value))
            );
    }

    private _filterUsers(value: string): string[] {
        let filterValue;
        if (value) {
            filterValue = value.toString().toLowerCase();
        } else {
            filterValue = '';
        }
        const filtered = this.groupsService.allUsers.filter(user => `${user.displayName.toLowerCase()}${user.email.toLowerCase()}`.includes(filterValue));
        return filtered;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close(this.form.getRawValue().assigned_to);
    }

    clearDialog() {
        this.dialogRef.close('');
    }

}
